import { Divider, Flex, HStack, Meter, type MeterWithValueProps, type Order, SmartSummary, Text } from '@talos/kyoko';
import { DDHIcon } from './DDHIcon';
import { DDHSummary } from './DDHSummary';

interface DDHProgressProps {
  order: Order;
  meterProps: MeterWithValueProps;
}

export const DDHProgress = ({ order, meterProps }: DDHProgressProps) => {
  if (!order.HedgeOrderStatus) {
    return null;
  }

  return (
    <Flex flexDirection="column" gap="spacingDefault" w="300px" data-testid="ddh-progress">
      <Text textTransform="uppercase" color="colorTextSubtle">
        Parent Order
      </Text>
      <SmartSummary entity={order} type="order" />
      <Meter {...meterProps} />

      <Divider />
      <HStack gap="spacingSmall" justifyContent="flex-start">
        <Text color="colorTextSubtle">DDH Summary</Text>
        <DDHIcon />
      </HStack>

      <DDHSummary order={order} usingMarketDataStatistics={false} />
    </Flex>
  );
};
