import { forwardRef, useCallback, useEffect, useImperativeHandle, useRef } from 'react';
import { useTheme } from 'styled-components';
import { useDynamicCallback } from '../../hooks/useDynamicCallback';
import { EMPTY_OBJECT } from '../../utils';
import { IconButton } from '../Button';
import { Box } from '../Core';
import { AutocompleteDropdown, FuseAutocompleteResult } from '../Form/AutocompleteDropdown';
import { useDropdownPopper } from '../Form/Dropdown';
import { Input } from '../Form/Input';
import { getDropdownItemHeight, useSearchSelect } from '../Form/SearchSelect';
import { FormControlSizes } from '../Form/types';
import { IconName } from '../Icons';
import type { AgGridSearchSelectDropdownProps } from './types';

export const AgGridSearchSelectDropdown = forwardRef(function AgGridSearchSelectDropdown<T>(
  {
    useSearchSelectParams,
    useDropdownParams,
    maxHeight,
    showClear,
    showDropdownSearch = true,
    searchPlaceholder,
    portalize = false,
    size = FormControlSizes.Small,
    ...cellEditorParams
  }: AgGridSearchSelectDropdownProps<T>,
  ref
) {
  const theme = useTheme();
  const value = useRef(cellEditorParams.value);

  useImperativeHandle(ref, () => {
    return {
      getValue: () => {
        return value.current;
      },
    };
  });

  const handleChange = useDynamicCallback((newValue: T | undefined) => {
    value.current = newValue;
    cellEditorParams.api.stopEditing();
  });

  const dropdownRef = useRef<HTMLElement>(cellEditorParams.eGridCell);
  const inputRef = useRef<HTMLInputElement>(null);

  // hardcoded for now but can be parameterized in the future
  const dropdownSize = FormControlSizes.Small;

  const searchSelect = useSearchSelect({
    ...useSearchSelectParams,
    itemSize: getDropdownItemHeight(theme.baseSize, dropdownSize),
    inputRef,
    onChange: handleChange,
  });
  const { openMenu, isOpen, getInputProps, reset } = searchSelect;

  const dropdown = useDropdownPopper({
    ...useDropdownParams,
    isOpen,
    referenceElement: dropdownRef.current,
    // There are two cases of clicking outside: 1) you click on another editable cell in the blotter, or 2) you just click outside of the blotter
    // In case 1 -- this below will not be called as ag grid will unmount this component. For 2), we call stopEditing since we are indeed stopping editing by clicking outside.
    onClickOutside: () => {
      cellEditorParams.api.stopEditing();
    },
  });

  const handleClearClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      reset();
      handleChange(undefined);
      e.preventDefault();
    },
    [reset, handleChange]
  );

  useEffect(() => {
    setTimeout(() => openMenu(), 0);
  }, [openMenu]);

  return (
    <Box>
      <AutocompleteDropdown
        {...searchSelect}
        {...dropdown}
        portalize={portalize}
        maxHeight={maxHeight}
        renderResult={FuseAutocompleteResult}
        size={dropdownSize}
        childrenAboveResults={
          <Input
            style={showDropdownSearch ? EMPTY_OBJECT : { display: 'none' }}
            placeholder={searchPlaceholder}
            size={dropdownSize}
            {...getInputProps({ ref: inputRef })}
            suffix={
              showClear &&
              value != null && (
                <IconButton
                  icon={IconName.Close}
                  size={FormControlSizes.Default - 0.5}
                  onClick={handleClearClick}
                  round
                  ghost
                  tabIndex={-1}
                  onFocus={e => e.stopPropagation()}
                  data-testid="dropdown-clear"
                />
              )
            }
          />
        }
      />
    </Box>
  );
});
