import { EXTRAS_MENU_TOOLTIP_PROPS, FormControlSizes, Toggle, VStack } from '@talos/kyoko';
import { CONTROL_TOOLTIPS } from 'containers/Blotters/tooltips';
import { useAppStateDispatch } from 'providers/AppStateProvider';
import {
  getPortfolioViewActions,
  usePortfolioViewStateSelector,
} from '../../stateManagement/portfolioViewLayoutSlice.hooks';
import { usePortfolioRouting } from '../../usePortfolioRouting';

const { updateShowZeroBalances, updateIncludeCash } = getPortfolioViewActions();
export const CommonHeaderMenuItems = () => {
  const dispatch = useAppStateDispatch();
  const menuOptions = usePortfolioRouting().selectedTab.activeControls?.menuOptions;

  const { showZeroBalances, includeCash } = usePortfolioViewStateSelector();
  return (
    <VStack gap="spacingDefault" alignItems="flex-start">
      {menuOptions?.has('showZeroBalances') && (
        <Toggle
          tooltip={CONTROL_TOOLTIPS.showZeroBalances}
          tooltipProps={EXTRAS_MENU_TOOLTIP_PROPS}
          size={FormControlSizes.Small}
          checked={showZeroBalances}
          onChange={() => {
            dispatch(updateShowZeroBalances(!showZeroBalances));
          }}
          label="Show Zero Balances"
        />
      )}
      {menuOptions?.has('includeCash') && (
        <Toggle
          tooltip={CONTROL_TOOLTIPS.includeCash}
          tooltipProps={EXTRAS_MENU_TOOLTIP_PROPS}
          size={FormControlSizes.Small}
          checked={includeCash}
          onChange={() => {
            dispatch(updateIncludeCash(!includeCash));
          }}
          label="Include Cash"
        />
      )}
    </VStack>
  );
};
