import type { ICellRendererParams, RowEvent } from 'ag-grid-community';
import { useCallback, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { useUnderlyingPriceContext } from '../../../contexts';
import { Flex } from '../../Core';
import { CurrencyIcon } from '../../CurrencyIcon';
import { InlineFormattedNumber } from '../../FormattedNumber';
import { Icon, IconName } from '../../Icons';
import { Text } from '../../Text';

export interface AgGridOptionGroupCellProps {
  formattedExpiration: string;
}

export const AG_GRID_OPTION_GROUP_KEY = 'optionGroup';
/**
 * Expects to be rendered full width in options chain as a group.
 */
export function AgGridOptionGroupCell(props: ICellRendererParams & AgGridOptionGroupCellProps) {
  const { node, value: rawExpiration, formattedExpiration } = props;
  const [expanded, setExpanded] = useState(node.expanded);

  useEffect(() => {
    const expandListener = (event: RowEvent) => setExpanded(event.node.expanded);

    node.addEventListener('expandedChanged', expandListener);

    return () => {
      node.removeEventListener('expandedChanged', expandListener);
    };
  }, [node]);

  const onClick = useCallback(() => node.setExpanded(!node.expanded), [node]);

  const { currency, underlyingAmountRecord } = useUnderlyingPriceContext();
  const underlyingAmount = underlyingAmountRecord?.[rawExpiration];

  return (
    <RowContainer
      px="spacingComfortable"
      cursor="pointer"
      onClick={onClick}
      h="100%"
      w="100%"
      justifyContent="space-between"
      alignItems="center"
    >
      <Text>{formattedExpiration}</Text>

      <Flex gap="spacingSmall" alignItems="center">
        {underlyingAmount?.value && (
          <Flex data-testid="underlying-index-expiry-header" alignItems="center" gap="spacingDefault">
            <Text color="colorTextDefault">Underlying Index</Text>
            <Flex alignItems="center" gap="spacingSmall">
              {currency && <CurrencyIcon currency={currency} />}
              <InlineFormattedNumber number={underlyingAmount.value} currency={underlyingAmount.currency} round />
            </Flex>
          </Flex>
        )}
        <Icon
          size="fontSizeSmall"
          icon={IconName.ChevronLeft}
          style={{
            transform: !expanded ? 'rotate(0deg)' : 'rotate(-90deg)',
            transition: 'transform 100ms linear',
          }}
        />
      </Flex>
    </RowContainer>
  );
}

const RowContainer = styled(Flex)`
  ${({ theme }) => css`
    background-color: ${theme.colors.gray['020']};
    transition-duration: 100ms;
    transition-property: background-color;
    :hover {
      background-color: ${theme.colors.gray['030']};
    }
  `}
`;
