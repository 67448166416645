import type { ReactNode } from 'react';
import type { Order } from '../../types';
import { Icon, IconName } from '../Icons';

interface DDHOrderIconProps {
  order: Order;
}

export function DDHOrderIcon({ order }: DDHOrderIconProps): ReactNode | null {
  if (!order.isDDHParentOrder && !order.isDDHHedgeOrder) {
    return null;
  }
  return (
    <Icon color="colors.blue.lighten" icon={order.isDDHParentOrder ? IconName.DeltaStacked : IconName.DeltaSingle} />
  );
}
