import {
  Button,
  CopyCsv,
  CopyJson,
  Divider,
  FormControlSizes,
  IconName,
  MarketAccountStatusEnum,
  MixpanelEvent,
  MixpanelEventProperty,
  NotificationVariants,
  filterByCellValueMenuItem,
  getRowNodesToOperateOn,
  useDisclosure,
  useDynamicCallback,
  useGetDefaultContextMenuItems,
  useGlobalToasts,
  useMixpanel,
  type Column,
  type FilterableProperty,
  type UseFilterBuilderOutput,
} from '@talos/kyoko';
import type { GetContextMenuItemsParams, ICellRendererParams, MenuItemDef } from 'ag-grid-enterprise';
import { compact } from 'lodash';
import { useMemo, useState } from 'react';
import { EnableDisableSelectedDialog } from './Dialogs/EnableDisableSelectedDialog';
import type { TradingControlsBlotterEntity } from './types';
import { useTradingControls } from './useTradingControls';
import { colIDToFilterBuilderKey } from './useTradingControlsFilter';

export function TradingControlsBlotterTableMenu({
  onEdit,
  enableTrading,
  disableTrading,
  ...params
}: ICellRendererParams<TradingControlsBlotterEntity> & {
  onEdit(marketAccount: TradingControlsBlotterEntity): void;
  enableTrading(marketAccounts: TradingControlsBlotterEntity[]): MenuItemDef;
  disableTrading(marketAccounts: TradingControlsBlotterEntity[]): MenuItemDef;
}) {
  const marketAccount = params.data;
  if (!marketAccount) {
    return null;
  }

  const enableOrDisable =
    marketAccount.Status === MarketAccountStatusEnum.Active
      ? disableTrading([marketAccount])
      : enableTrading([marketAccount]);

  return (
    <>
      <Button size={FormControlSizes.Small} ghost={true} onClick={() => onEdit(marketAccount)}>
        Edit &quot;{marketAccount.DisplayName}&quot;
      </Button>
      {enableOrDisable && (
        <Button size={FormControlSizes.Small} ghost={true} onClick={() => enableOrDisable.action?.()}>
          {marketAccount.Status === MarketAccountStatusEnum.Active ? 'Disable' : 'Enable'} Trading
        </Button>
      )}
      <Divider orientation="horizontal" />
      <CopyJson {...params} />
      <CopyCsv {...params} />
    </>
  );
}

export interface UseTradingControlsBlotterTableMenu {
  columns: Column[];
  getContextMenuItems: (params: GetContextMenuItemsParams) => Array<string | MenuItemDef>;
  dialogComponents: JSX.Element[];
}

export function useTradingControlsBlotterTableMenu({
  onEdit,
  openClause,
  filterableProperties,
}: {
  onEdit(marketAccount: TradingControlsBlotterEntity): void;
  openClause: UseFilterBuilderOutput['addAndOpenClause'];
  filterableProperties: FilterableProperty<string>[];
}): UseTradingControlsBlotterTableMenu {
  const { toggleTrading } = useTradingControls();
  const dialog = useDisclosure();
  const mixpanel = useMixpanel();
  const { add: addToast } = useGlobalToasts();
  const [dialogMode, setDialogMode] = useState<MarketAccountStatusEnum>(MarketAccountStatusEnum.Active);
  const [dialogItemsToChange, setDialogItemsToChange] = useState<TradingControlsBlotterEntity[]>([]);

  const onEnableDisable = useDynamicCallback(
    async (marketAccounts: TradingControlsBlotterEntity[], mode: MarketAccountStatusEnum) => {
      const promises: Promise<any>[] = [];
      mixpanel.track(MixpanelEvent.EnableTradingMarketAccount, {
        [MixpanelEventProperty.Enabled]: mode === MarketAccountStatusEnum.Active,
      });
      for (const marketAccount of marketAccounts) {
        promises.push(
          toggleTrading(marketAccount.Name, mode)
            .then(() => {
              addToast({
                text: `Updated ${marketAccount.DisplayName}`,
                variant: NotificationVariants.Positive,
              });
            })
            .catch(e => {
              addToast({
                text: e?.toString() || `Could not update ${marketAccount.DisplayName}`,
                variant: NotificationVariants.Negative,
              });
            })
        );
      }
      await Promise.all(promises);
    }
  );

  const openEnableDisableDialog = useDynamicCallback(
    (items: TradingControlsBlotterEntity[], mode: MarketAccountStatusEnum) => {
      setDialogItemsToChange(items);
      setDialogMode(mode);
      dialog.open();
    }
  );

  const enableItem = useDynamicCallback((marketAccounts: TradingControlsBlotterEntity[]) =>
    toggleMarketAccountModeMenuItem({
      marketAccounts,
      mode: MarketAccountStatusEnum.Active,
      openEnableDisableDialog,
    })
  );
  const disableItem = useDynamicCallback((marketAccounts: TradingControlsBlotterEntity[]) =>
    toggleMarketAccountModeMenuItem({
      marketAccounts,
      mode: MarketAccountStatusEnum.Disabled,
      openEnableDisableDialog,
    })
  );

  const columns = useMemo<Column[]>(
    () =>
      compact([
        {
          id: 'menu',
          type: 'hamburgerMenu',
          params: {
            renderItems: params => (
              <TradingControlsBlotterTableMenu
                {...params}
                onEdit={onEdit}
                enableTrading={enableItem}
                disableTrading={disableItem}
              />
            ),
          },
        },
      ]),
    [enableItem, onEdit, disableItem]
  );

  const getDefaultContextMenuItems = useGetDefaultContextMenuItems();

  const getContextMenuItems = useDynamicCallback((params: GetContextMenuItemsParams) => {
    const selectedItems: TradingControlsBlotterEntity[] = getRowNodesToOperateOn(params).map(node => node.data);
    return compact([
      ...filterByCellValueMenuItem({
        params,
        filterableProperties,
        openClause,
        colIDToFilterBuilderKey,
        mixpanel,
      }),
      {
        name: `Edit "${params?.node?.data?.DisplayName}"`,
        action: () => onEdit(params?.node?.data),
        icon: `<i class="ag-icon ${IconName.Pencil}"/>`,
      },
      enableItem(selectedItems),
      disableItem(selectedItems),
      'separator',
      ...getDefaultContextMenuItems(params),
    ]);
  });

  return {
    columns,
    getContextMenuItems,
    dialogComponents: [
      <EnableDisableSelectedDialog
        key="enable-disable-selected-dialog"
        selectedItems={dialogItemsToChange}
        enableDisableDialog={dialog}
        onConfirm={(marketAccounts: TradingControlsBlotterEntity[]) => {
          onEnableDisable(marketAccounts, dialogMode);
        }}
        mode={dialogMode}
      />,
    ],
  };
}

interface TradingControlsMenuItemProps {
  marketAccounts: TradingControlsBlotterEntity[];
  mode: MarketAccountStatusEnum;
  openEnableDisableDialog: (items: TradingControlsBlotterEntity[], mode: MarketAccountStatusEnum) => void;
}

const toggleMarketAccountModeMenuItem = ({
  marketAccounts,
  mode,
  openEnableDisableDialog,
}: TradingControlsMenuItemProps): MenuItemDef | null => {
  const oppositeMode =
    mode === MarketAccountStatusEnum.Active ? MarketAccountStatusEnum.Disabled : MarketAccountStatusEnum.Active;
  const filteredMarketAccounts = marketAccounts.filter(marketAccount => marketAccount.Status === oppositeMode);

  if (filteredMarketAccounts.length < 1) {
    return null;
  }
  const label = mode === MarketAccountStatusEnum.Active ? 'Enable' : 'Disable';
  const name =
    filteredMarketAccounts.length === 1
      ? `${label} Trading`
      : `${label} Trading for ${filteredMarketAccounts.length} Market Accounts`;
  return {
    name,
    action: () => {
      openEnableDisableDialog(filteredMarketAccounts, mode);
    },
    icon: `<i class="ag-icon ${
      mode === MarketAccountStatusEnum.Active ? IconName.CheckCircleSolid : IconName.CloseCircleSolid
    }"/>`,
  };
};
