import type { Placement } from '@popperjs/core';
import { memo, useState, type CSSProperties, type ReactNode } from 'react';
import styled from 'styled-components';

import { isEqual } from 'lodash';
import { Box, type BoxProps } from '../Core';
import { Popover, usePopoverState } from '../Popover';

export type TooltipProps = Parameters<typeof usePopoverState>[0] & {
  tooltip: ReactNode;
  children: ReactNode;
  trigger?: string;
  placement?: Placement;
  delay?: number;
  preventOverflow?: boolean;
  showUnderline?: boolean;
  disabled?: boolean;
  targetStyle?: CSSProperties;
  /** Set a custom maxWidth on the content of the tooltip. Defaults to 300px. */
  contentMaxWidth?: React.CSSProperties['maxWidth'];
};

export const Tooltip = memo(
  function Tooltip({
    children,
    tooltip,
    trigger = 'hover',
    placement = 'top',
    delay = 200,
    showUnderline = false,
    disabled = false,
    isSmall = true,
    usePortal = true,
    contentMaxWidth = '300px',
    ...props
  }: TooltipProps) {
    const popover = usePopoverState({
      trigger,
      placement,
      delay,
      onOpen: props.onOpen,
      isSmall,
      usePortal,
      closeOnClickOutside: props.closeOnClickOutside,
    });

    return (
      <Popover {...popover} {...props} tabIndex={-1}>
        {showUnderline ? <Underlined isOpen={popover.isOpen}>{children}</Underlined> : children}
        {tooltip && !disabled && <Box maxWidth={contentMaxWidth}>{tooltip}</Box>}
      </Popover>
    );
  },
  (p, n) => isEqual(p, n)
);

/**
 * Exactly the same as a Tooltip, but will only render the tooltip part of itself when the root element is hovered.
 *
 * Note that this will re-mount the children inside the JITTooltip, which can cause issues in some situations.
 *
 * This small wrapper can very well be built into the Tooltip itself, and it probably should. But for now, keeping them
 * separate.
 */
export const JITTooltip = (props: TooltipProps & { wrapperProps?: BoxProps }) => {
  const [hovered, setHovered] = useState(false);
  return (
    <Box
      data-testid="jit-tooltip"
      {...props.wrapperProps}
      onMouseOver={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      {hovered ? <Tooltip {...props} /> : props.children}
    </Box>
  );
};

const Underlined = styled.span<{ isOpen: boolean }>`
  position: relative;
  display: flex;

  // Use pseudo-element here to prevent the dashed border from taking up space
  &::before {
    content: '';
    position: absolute;
    bottom: 0;
    width: 100%;
    border-bottom: 1px dotted;
    border-bottom-color: ${({ theme }) => theme.colorTextMuted};
    transition: border-bottom-color 200ms;
    ${({ isOpen, theme }) => isOpen && `border-bottom-color: ${theme.colorTextSubtle}`};
  }
`;
