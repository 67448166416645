import { Box, HStack, Text, Toggle, usePortal, type ToggleProps } from '@talos/kyoko';
import { CONTROL_TOOLTIPS } from 'containers/Blotters/tooltips';
import { useAppStateDispatch } from 'providers/AppStateProvider';
import { useMemo } from 'react';
import { Route } from 'react-router';
import styled, { useTheme, type DefaultTheme } from 'styled-components';
import { PMSDateRangeFilter } from './contextSelection/PMSDateRangeFilter';
import { PMSMarketAccountSelector } from './contextSelection/PMSMarketAccountSelector';
import { PMSRollupSubAccountSelector } from './contextSelection/PMSRollupSubAccountSelector';
import { getPortfolioLayoutOptions } from './layoutConfiguration/getPortfolioLayoutOptions';
import {
  getPortfolioViewActions,
  usePortfolioViewStateSelector,
} from './stateManagement/portfolioViewLayoutSlice.hooks';
import { PMS_HEADER_MENU_PORTAL_ID } from './types/types';
import { usePortfolioRouting } from './usePortfolioRouting';

const PortfolioWorkspaceLabel = () => {
  const layoutRoutes = useMemo(() => {
    const layoutOptions = getPortfolioLayoutOptions();
    return layoutOptions.map(layout => (
      <Route key={layout.route} path={`/portfolio${layout.route}`}>
        <HStack gap="spacingTiny" data-testid="portfolio-dashboard-workspace-label">
          <Text color="colorTextImportant" lineHeight="20px" fontSize="fontSizeLg">
            {layout.label}
          </Text>
        </HStack>
      </Route>
    ));
  }, []);

  return <HStack gap="spacingTiny">{layoutRoutes}</HStack>;
};

const FilterControls = () => {
  const filterContext = usePortfolioRouting().selectedTab.activeFilters;

  const filterSet = useMemo(() => {
    return new Set(filterContext ?? []);
  }, [filterContext]);

  return (
    <HStack gap="spacingDefault">
      {filterSet.has('SubAccounts') && <PMSRollupSubAccountSelector />}
      {filterSet.has('MarketAccounts') && <PMSMarketAccountSelector />}
      {filterSet.has('DateRange') && <PMSDateRangeFilter />}
    </HStack>
  );
};

const { updateShowRollupHierarchy } = getPortfolioViewActions();

const CONTROL_TOOLTIP_PROPS: ToggleProps['tooltipProps'] = { placement: 'bottom' };

export const PortfolioWorkspaceHeader = () => {
  const theme = useTheme();
  const { showRollupHierarchy } = usePortfolioViewStateSelector();
  const dispatch = useAppStateDispatch();
  const { setPortalRef: headerMenuRef } = usePortal(PMS_HEADER_MENU_PORTAL_ID);
  const isShowRollupHierarchyVisible = usePortfolioRouting().selectedTab.activeControls?.showRollupHierarchy ?? false;

  return (
    <HeaderHStack
      w="100%"
      background="backgroundContent"
      data-testid="portfolio-workspace-header"
      theme={theme}
      justifyContent="space-between"
      alignItems="center"
      h="48px"
    >
      <HStack gap="spacingDefault">
        <PortfolioWorkspaceLabel />
        <FilterControls />
      </HStack>
      <HStack gap="spacingDefault" data-testid="portfolio-dashboard-controls">
        {isShowRollupHierarchyVisible && (
          <Toggle
            tooltip={CONTROL_TOOLTIPS.showRollupHierarchy}
            tooltipProps={CONTROL_TOOLTIP_PROPS}
            data-testid="rollup-hierarchy-toggle"
            label="Show Rollup Hierarchy"
            checked={showRollupHierarchy}
            onChange={value => {
              dispatch(updateShowRollupHierarchy(value));
            }}
          />
        )}
        <Box ref={headerMenuRef} />
      </HStack>
    </HeaderHStack>
  );
};

const HeaderHStack = styled(HStack)<{ theme: DefaultTheme }>`
  border-bottom: solid 1px ${({ theme }) => theme.colors.gray['010']};
  padding: ${({ theme }) => theme.spacingDefault + 'px'};
  gap: ${({ theme }) => theme.spacingDefault + 'px'};
  justify-content: space-between;
  width: 100%;
`;
