import type { FilterClause, UseFilterBuilderProps } from '@talos/kyoko';
import {
  ACTION,
  Accordion,
  AccordionBody,
  Box,
  Button,
  ButtonVariants,
  Divider,
  FilterBuilder,
  FilterBuilderClearAllButton,
  FilterBuilderToggleButton,
  FormControlSizes,
  HStack,
  IconName,
  MixpanelEventSource,
  TabSize,
  useAccordionFilterBuilder,
} from '@talos/kyoko';
import { useContextBlotterFilter } from 'providers/ContextBlotterFilterProvider/useContextBlotterFilter';
import { useCallback, useEffect, useMemo } from 'react';
import { useRoleAuth, useTransfersDrawer } from '../../../../hooks';
import type { BalancesBlotterShowBy } from '../../../Blotters/BalancesV2/types';
import { getEmptyTransferForm } from '../../../Transfers/types';
import { TopTabContentContainer } from '../../styles';
import { TreasuryManagementActionType } from '../TreasuryManagementReducer';
import { useTreasuryManagementFilters } from '../providers/TreasuryManagementFiltersProvider';
import { useTreasuryManagementInteractions } from '../providers/TreasuryManagementInteractionsProvider';
import {
  useTreasuryManagementContext,
  useTreasuryManagementTabs,
} from '../providers/TreasuryManagementStateAndTabsProvider';
import { ShowByButtons } from './ShowByButtons';
import { SnapshotDateSelector } from './SnapshotDateSelector';

interface TopTabContentProps {
  initialFiltersOpen: boolean;
  initialFilterClauses: FilterClause[];
}

export function TopTabContent({ initialFiltersOpen, initialFilterClauses }: TopTabContentProps) {
  const { filterableProperties, onFilterClausesChanged } = useTreasuryManagementFilters();
  const { setOpenClause } = useTreasuryManagementInteractions();
  const { cloneCurrentTab } = useTreasuryManagementTabs();
  const { state, dispatch } = useTreasuryManagementContext();
  const { openTransfersDrawer } = useTransfersDrawer();
  const { isAuthorized } = useRoleAuth();
  const { additionalFilterState, filterPropsMutator } = useContextBlotterFilter<{
    filterBuilderProps: UseFilterBuilderProps;
  }>();

  const handleShowByClick = useCallback(
    (type: BalancesBlotterShowBy) => {
      dispatch({
        type: TreasuryManagementActionType.ShowByChange,
        payload: {
          showBy: type,
        },
      });
    },
    [dispatch]
  );

  useEffect(() => {
    if (additionalFilterState && 'MarketAccounts' in additionalFilterState) {
      dispatch({
        type: TreasuryManagementActionType.MarketAccountsFilterChange,
        payload: {
          MarketAccounts: additionalFilterState.MarketAccounts,
        },
      });
    }
  }, [additionalFilterState, dispatch]);

  const filterBuilderProps = useMemo(() => {
    return filterPropsMutator({
      filterBuilderProps: {
        properties: filterableProperties,
        initialFilterClauses,
        onFilterClausesChanged,
      },
    }).filterBuilderProps;
  }, [filterPropsMutator, filterableProperties, initialFilterClauses, onFilterClausesChanged]);

  const { accordion, filterBuilder, openClause } = useAccordionFilterBuilder({
    filterBuilderProps,
    accordionProps: { initialOpen: initialFiltersOpen },
  });

  // Register the openClause function with the interactions provider
  useEffect(() => {
    setOpenClause(() => openClause);
  }, [openClause, setOpenClause]);

  return (
    <TopTabContentContainer>
      <HStack px="spacingDefault" gap="spacingDefault" justifyContent="space-between" w="100%" py="spacingDefault">
        <HStack gap="spacingDefault">
          <ShowByButtons showBy={state.showBy} onClick={handleShowByClick} size={TabSize.Small} />
          <Divider orientation="vertical" />
          <SnapshotDateSelector />
        </HStack>
        <HStack gap="spacingDefault">
          <Button
            startIcon={IconName.Duplicate}
            variant={ButtonVariants.Default}
            size={FormControlSizes.Small}
            onClick={cloneCurrentTab}
          >
            Clone Tab
          </Button>
          <Divider orientation="vertical" />
          <FilterBuilderClearAllButton
            removeAllFilterClauses={filterBuilder.removeAllFilterClauses}
            disabled={filterBuilder.filterClauses.length === 0}
            size={FormControlSizes.Small}
          />
          <FilterBuilderToggleButton
            filterClauses={filterBuilder.filterClauses}
            isOpen={accordion.isOpen}
            onClick={() => accordion.toggle()}
            size={FormControlSizes.Small}
          />
          {isAuthorized(ACTION.SUBMIT_TRANSFER) && (
            <>
              <Divider orientation="vertical" />
              <Button
                size={FormControlSizes.Small}
                onClick={() =>
                  openTransfersDrawer({
                    initialTransfer: getEmptyTransferForm(),
                    source: MixpanelEventSource.TreasuryManagement,
                  })
                }
                startIcon={IconName.ArrowLeftRight}
              >
                New Transfer
              </Button>
            </>
          )}
        </HStack>
      </HStack>
      <Box w="100%">
        <Accordion {...accordion}>
          <AccordionBody p="0" px="spacingMedium" borderTopWhenOpened>
            <Box py="spacingMedium">
              <FilterBuilder {...filterBuilder} />
            </Box>
          </AccordionBody>
        </Accordion>
      </Box>
    </TopTabContentContainer>
  );
}
