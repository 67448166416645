import {
  HEDGE_POSITION_STATUS,
  ORDER,
  useObservable,
  useObservableValue,
  useSubscription,
  wsScanToMap,
  wsStitchWith,
  wsSubscriptionCache,
  type IHedgePositionStatus,
  type MinimalSubscriptionResponse,
  type Order,
} from '@talos/kyoko';
import { pick } from 'lodash';
import { createContext, useContext, useMemo, type PropsWithChildren } from 'react';
import { map, shareReplay, type Observable } from 'rxjs';
import { v4 as uuid } from 'uuid';
import { useFeatureFlag } from '../hooks/useFeatureFlag';
import { OPEN_ORDER_STATUSES } from './constants';

type HedgePositionStatusContextProps = {
  /**
   * Observable of the latest hedge position status deltas. Useful for tables
   */
  hedgePositionStatusDeltasObs: Observable<MinimalSubscriptionResponse<IHedgePositionStatusRow>>;
  /**
   * Observable of the lastest hedge position status in a lookup map. Useful for quick lookups.
   */
  hedgePositionStatusObs: Observable<Map<string, IHedgePositionStatusRow>>;
  /**
   * Observable for the global status of the autohedger.
   */
  globalHedgePositionStatusObs: Observable<MinimalSubscriptionResponse<IHedgePositionStatus, string>>;

  /**
   * The global hedge rule, if any.
   */
  globalHedgeRule: IHedgePositionStatus | undefined;
};

const HedgePositionStatusContext = createContext<HedgePositionStatusContextProps | null>(null);

/**
 * A unique string to use as a throwaway value for the Group field in the order.
 * If the Group field is not present, we use this value to ensure that the stitcher
 * does not join onto a row where it should not belong.
 */
const HEDGE_GROUP_UNIQUE_THROWAWAY = uuid() + '_HEDGE_GROUP_UNIQUE_THROWAWAY';

export type IHedgePositionStatusOrder = Pick<
  Order,
  'AvgPx' | 'CumQty' | 'Side' | 'Symbol' | 'OrderID' | 'Currency' | 'Strategy'
>;

export interface IHedgePositionStatusRow extends IHedgePositionStatus {
  Order?: IHedgePositionStatusOrder;
}

export function HedgePositionStatusProvider({ children }: PropsWithChildren) {
  const { enableAutoHedging, enableOrderLinkInHedgeRule } = useFeatureFlag();

  const hedgeSubRequest = useMemo(() => {
    return enableAutoHedging
      ? {
          name: HEDGE_POSITION_STATUS,
          tag: 'HedgePositionStatusProvider_Position',
        }
      : null;
  }, [enableAutoHedging]);

  const globalHedgeSubRequest = useMemo(() => {
    return enableAutoHedging
      ? {
          name: HEDGE_POSITION_STATUS,
          tag: 'HedgePositionStatusProvider_Global',
          HedgeControlType: 'Global',
        }
      : null;
  }, [enableAutoHedging]);

  const ordersRequest = useMemo(() => {
    // We only request order data if both autohedging and order link in hedge rule are enabled
    return enableAutoHedging && enableOrderLinkInHedgeRule
      ? {
          name: ORDER,
          tag: 'HedgePositionStatusProvider',
          RequestSource: 'Hedger',
          Statuses: OPEN_ORDER_STATUSES,
        }
      : null;
  }, [enableAutoHedging, enableOrderLinkInHedgeRule]);

  const { data: globalHedgePositionStatusObsRaw } = useSubscription<IHedgePositionStatus>(globalHedgeSubRequest);
  const globalHedgePositionStatusObs = useObservable(
    // Add cache to avoid late subscribers missing the initial data
    () =>
      globalHedgePositionStatusObsRaw.pipe(
        wsSubscriptionCache(hedgePositionStatus => hedgePositionStatus.HedgeRuleID, HEDGE_POSITION_STATUS)
      ),
    [globalHedgePositionStatusObsRaw]
  );

  const { data: hedgePositionStatusObsRaw } = useSubscription<IHedgePositionStatus>(hedgeSubRequest);
  const { data: ordersObs } = useSubscription<Order>(ordersRequest);

  const hedgePositionStatusDeltasObs: HedgePositionStatusContextProps['hedgePositionStatusDeltasObs'] = useObservable(
    () =>
      hedgePositionStatusObsRaw.pipe(
        wsStitchWith<IHedgePositionStatus, Order, IHedgePositionStatusRow>({
          secondarySource: ordersObs,
          getPrimaryTypeKey: hedgePositionStatus => hedgePositionStatus.HedgeRuleID,
          // The backlink to the HedgeRuleID is the Group field in the order, if the workflow is 'Hedging'.
          getSecondaryTypeKey: order =>
            order.RequestSource === 'Hedger' && order.Group ? order.Group : HEDGE_GROUP_UNIQUE_THROWAWAY,
          stitch: (hedgePositionStatus, order) => {
            // If the order is not present, we don't need to stitch it.
            // if the HedgeOrderID is not present, we don't need to stitch it.
            // this is important since we need to drop order data if the hedge rule is not in a state where it has an order.
            if (!order || !hedgePositionStatus.HedgeOrderID) {
              return hedgePositionStatus;
            }

            return {
              ...hedgePositionStatus,
              Order: pick(order, [
                'Side',
                'CumQty',
                'AvgPx',
                'Symbol',
                'OrderID',
                'Currency',
                'Strategy',
              ] satisfies (keyof IHedgePositionStatusOrder)[]),
            };
          },
        }),
        wsSubscriptionCache(hedgePositionStatus => hedgePositionStatus.HedgeRuleID, HEDGE_POSITION_STATUS)
      ),
    [hedgePositionStatusObsRaw, ordersObs]
  );

  const hedgePositionStatusObs: HedgePositionStatusContextProps['hedgePositionStatusObs'] = useObservable(
    () =>
      hedgePositionStatusDeltasObs.pipe(
        wsScanToMap({ getUniqueKey: hedgePositionStatus => hedgePositionStatus.HedgeRuleID, newMapEachUpdate: true }),
        shareReplay({
          bufferSize: 1,
          refCount: true,
        })
      ),
    [hedgePositionStatusDeltasObs]
  );

  const globalHedgeRule = useObservableValue(
    () =>
      globalHedgePositionStatusObs.pipe(
        map(json => {
          return json.data.at(0);
        })
      ),
    [globalHedgePositionStatusObs]
  );

  const value: HedgePositionStatusContextProps = useMemo(
    () => ({
      hedgePositionStatusObs,
      globalHedgePositionStatusObs,
      hedgePositionStatusDeltasObs,
      globalHedgeRule,
    }),
    [hedgePositionStatusObs, globalHedgePositionStatusObs, hedgePositionStatusDeltasObs, globalHedgeRule]
  );

  return <HedgePositionStatusContext.Provider value={value}>{children}</HedgePositionStatusContext.Provider>;
}

export function useHedgePositionStatus() {
  const context = useContext(HedgePositionStatusContext);
  if (context === null) {
    throw new Error('Missing HedgePositionStatusProvider further up in the tree. Did you forget to add it?');
  }
  return context;
}
