import type { WritableDraft } from 'immer';

import {
  FieldValidationLevel,
  toBigWithDefault,
  validatePrecision,
  type FieldValidationRule,
  type NumericField,
} from '@talos/kyoko';
import type { CareOrderState } from './types';

export const quantityValidation: FieldValidationRule<NumericField, WritableDraft<CareOrderState>> = (
  field,
  context
) => {
  if (!context) {
    return null;
  }

  const { form } = context;

  const security = form?.symbolField.value;
  if (!security) {
    return null;
  }

  const quantity = toBigWithDefault(field.value, 0);

  const { MinimumSize, MinSizeIncrement } = security;

  const minSize = MinimumSize;
  const minIncr = MinSizeIncrement;

  if (quantity.lt(minSize)) {
    return {
      message: `Min quantity is ${minSize}`,
      level: FieldValidationLevel.Error,
    };
  }

  if (!validatePrecision(minIncr, field.value)) {
    return {
      message: `Min increment is ${minIncr}`,
      level: FieldValidationLevel.Error,
    };
  }

  return null;
};
