import {
  Card,
  Divider,
  Flex,
  HStack,
  Icon,
  IconName,
  InlineFormattedNumber,
  Side,
  Text,
  useSecuritiesContext,
  useStrategiesContext,
  type HedgeOrderStatus,
  type Order,
} from '@talos/kyoko';
import { useDDHCalculateHedgeQty } from './useDDHCalculateHedgeQty';

interface DDHSummaryProps {
  order: Order;
  usingMarketDataStatistics: boolean;
  liveHedgeOrderStatus?: HedgeOrderStatus;
  showFees?: boolean;
}

/**
 * This component renders DDH order summary based on provided order and it's HedgeOrderStatus unless
 * liveHedgeOrderStatus is provided to replace order.HedgeOrderStatus
 *
 * @param order
 * @param usingMarketDataStatistics use MarketDataStatistics subscription to calculate Estimated Hedge Qty
 * @param liveHedgeOrderStatus overrides static order.HedgeOrderStatus
 * @param showFees
 */
export const DDHSummary = ({ order, usingMarketDataStatistics, liveHedgeOrderStatus, showFees }: DDHSummaryProps) => {
  const { securitiesBySymbol } = useSecuritiesContext();
  const { strategiesByName } = useStrategiesContext();
  const { estimatedHedgeQty, delta } = useDDHCalculateHedgeQty(order, usingMarketDataStatistics);

  const hedgeOrderStatus = liveHedgeOrderStatus ?? order.HedgeOrderStatus;
  if (!hedgeOrderStatus) {
    return null;
  }

  const hedgeSecurity = securitiesBySymbol.get(hedgeOrderStatus.HedgeSymbol);
  const hedgeCurrency = hedgeOrderStatus.Currency;
  const hedgePriceCurrency = hedgeSecurity?.QuoteCurrency;
  const hedgeStrategy = strategiesByName.get(hedgeOrderStatus.HedgeStrategy);

  return (
    <Card>
      <Flex flexDirection="column" gap="spacingDefault">
        {usingMarketDataStatistics && estimatedHedgeQty && !estimatedHedgeQty.eq(0) && (
          <>
            <HStack justifyContent="space-between" gap="spacingDefault" data-testid="ddh-summary-estimated-hedge-qty">
              <Text>Estimated Hedge Qty</Text>
              <InlineFormattedNumber
                number={estimatedHedgeQty.toFixed()}
                currency={hedgeCurrency}
                increment={hedgeSecurity?.DefaultSizeIncrement}
                specification={hedgeSecurity?.SizeDisplaySpec}
                align="right"
              />
            </HStack>

            <HStack justifyContent="space-between">
              <Text>Delta per Options Contract (Live)</Text>
              <Text>
                <InlineFormattedNumber number={delta?.toFixed()} />
              </Text>
            </HStack>

            <Divider orientation="horizontal" />
          </>
        )}
        {!usingMarketDataStatistics && hedgeOrderStatus.LastDelta && (
          <>
            <HStack justifyContent="space-between" data-testid="ddh-summary-last-delta">
              <Text>Last Fill Delta</Text>
              <Text>
                <InlineFormattedNumber number={hedgeOrderStatus.LastDelta} />
              </Text>
            </HStack>

            <Divider orientation="horizontal" />
          </>
        )}

        <HStack justifyContent="space-between" gap="spacingDefault" data-testid="ddh-summary-hedge-strategy">
          <Text>{hedgeSecurity?.DisplaySymbol ?? hedgeOrderStatus.HedgeSymbol}</Text>
          <Text color="colorTextSubtle" textTransform="uppercase">
            {hedgeStrategy?.DisplayName ?? hedgeOrderStatus.HedgeStrategy}
          </Text>
        </HStack>

        <HStack justifyContent="space-between" gap="spacingDefault" data-testid="ddh-summary-total-qty">
          <Side side={hedgeOrderStatus.HedgeSide}>{hedgeOrderStatus.HedgeSide}</Side>
          <InlineFormattedNumber
            number={hedgeOrderStatus.totalQty}
            currency={hedgeCurrency}
            increment={hedgeSecurity?.DefaultSizeIncrement}
            specification={hedgeSecurity?.SizeDisplaySpec}
            align="right"
          />
        </HStack>

        <HStack justifyContent="space-between" gap="spacingDefault" data-testid="ddh-summary-filled-details">
          <Text color="colorTextSubtle" textTransform="uppercase">
            Filled
          </Text>
          <HStack gap="spacingSmall">
            <InlineFormattedNumber
              number={hedgeOrderStatus.CumHedgeQty}
              currency={hedgeCurrency}
              increment={hedgeSecurity?.DefaultSizeIncrement}
              specification={hedgeSecurity?.SizeDisplaySpec}
              align="right"
            />
            <Text color="colorTextSubtle">
              <Icon icon={IconName.AtSymbol} />
            </Text>
            <InlineFormattedNumber
              number={showFees ? hedgeOrderStatus.AvgHedgePxAllIn : hedgeOrderStatus.AvgHedgePx}
              currency={hedgePriceCurrency}
              startIcon={showFees ? IconName.Fees : undefined}
              increment={hedgeSecurity?.DefaultPriceIncrement}
              specification={hedgeSecurity?.PriceDisplaySpec}
              align="right"
            />
          </HStack>
        </HStack>
      </Flex>
    </Card>
  );
};
