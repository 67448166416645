import {
  BlotterTable,
  Box,
  Button,
  ButtonVariants,
  Divider,
  EXPANDABLE_HEADER_HEIGHT,
  FormControlSizes,
  HStack,
  Icon,
  IconButton,
  IconName,
  Input,
  Toggle,
  VStack,
  columnTypes,
  createCSVFileName,
  filterByCellValueMenuItem,
  filterByColumnMainMenuItems,
  selectAll,
  useAggDeltaUpdatesPipe,
  useBlotterTable,
  useDefaultColumns,
  useDynamicCallback,
  useGetDefaultContextMenuItems,
  useMixpanel,
  usePersistedBlotterTable,
  usePersistedRowGroupsOpenedState,
  type BlotterTableRow,
  type Column,
  type RowGroupsOpenedState,
} from '@talos/kyoko';
import type {
  GetContextMenuItems,
  GetContextMenuItemsParams,
  GetMainMenuItemsParams,
  GridOptions,
} from 'ag-grid-community';
import { CONTROL_TOOLTIPS } from 'containers/Blotters/tooltips';
import { compact } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { getBalanceKey, type Balance } from '../../../../types';
import { balancesBlotterAggSpecs } from '../../../Blotters/BalancesV2/BalancesV2Blotter';
import { BalancesBlotterSummaryLine } from '../../../Blotters/BalancesV2/SummaryLine/BalancesBlotterSummaryLine';
import type { BalancesBlotterColumnSpecification } from '../../../Blotters/BalancesV2/types';
import { balanceColIDToFilterBuilderKey } from '../../../Blotters/BalancesV2/useBalancesV2Filter';
import { AsOfDateBadge } from '../../components/AsOfDateBadge';
import { TreasuryManagementActionType } from '../TreasuryManagementReducer';
import { useTreasuryManagementBalances } from '../providers/TreasuryManagementBalancesProvider';
import { useTreasuryManagementFilters } from '../providers/TreasuryManagementFiltersProvider';
import { useTreasuryManagementInteractions } from '../providers/TreasuryManagementInteractionsProvider';
import { useTreasuryManagementContext } from '../providers/TreasuryManagementStateAndTabsProvider';
import type { TreasuryBalance } from '../types';
import { useTreasuryColumnDefinitions, type ExtraTreasuryColumns } from './useTreasuryColumnDefinitions';

const ABOVE_BLOTTER_MENU_ROW_HEIGHT = '40px';
const QUICK_FILTER_KEYS: (keyof Balance)[] = [
  'marketAccountName',
  'marketAccountGroup',
  'Currency',
  'Market',
  'Amount',
  'Equivalent',
];

export type TreasuryBlotterColumnSpecification = BalancesBlotterColumnSpecification | ExtraTreasuryColumns;

interface TreasuryManagementBlotterProps {
  blotterID: string;
  defaultColumns: TreasuryBlotterColumnSpecification[];
  defaultRowGroupsOpened: RowGroupsOpenedState;
}

export const TreasuryManagementBlotter = ({
  blotterID,
  defaultColumns: defaultBlotterColumns,
  defaultRowGroupsOpened,
}: TreasuryManagementBlotterProps) => {
  const mixpanel = useMixpanel();
  const columnDefinitions = useTreasuryColumnDefinitions();
  const defaultColumns = useDefaultColumns(defaultBlotterColumns, columnDefinitions);
  const { filterableProperties } = useTreasuryManagementFilters();
  const { state, dispatch } = useTreasuryManagementContext();
  const [selectedRows, setSelectedRows] = useState<Balance[]>([]);

  const { setGoToGroupRow, openClause } = useTreasuryManagementInteractions();

  const { balancesDeltaObs } = useTreasuryManagementBalances();

  const getExtraMainMenuItems = useDynamicCallback((params: GetMainMenuItemsParams) => {
    if (openClause) {
      return filterByColumnMainMenuItems({
        params,
        colIDToFilterBuilderKey: balanceColIDToFilterBuilderKey,
        openClause,
        mixpanel,
      });
    }

    return [];
  });
  const getDefaultContextMenuItems = useGetDefaultContextMenuItems();

  const getContextMenuItems: GetContextMenuItems<TreasuryBalance> = useDynamicCallback(
    (params: GetContextMenuItemsParams) => {
      const items = [selectAll(params, mixpanel), 'separator', ...getDefaultContextMenuItems(params)];

      if (openClause) {
        items.unshift(
          ...filterByCellValueMenuItem({
            params,
            filterableProperties,
            openClause,
            colIDToFilterBuilderKey: balanceColIDToFilterBuilderKey,
            mixpanel,
          })
        );
      }

      return compact(items);
    }
  );

  const persisted = usePersistedBlotterTable(blotterID, {
    persistFilter: false, // this is handled elsewhere in treasury mgmt
    columns: defaultColumns,
  });

  const persistedRowGroupsOpened = usePersistedRowGroupsOpenedState(blotterID, {
    defaultRowGroupsOpened,
  });

  const [groupAccounts, setGroupAccounts] = useState<boolean>(columnsToGroupAccounts(persisted.columns));
  const handleColumnsChanged = useDynamicCallback((columns: Column<any>[]) => {
    setGroupAccounts(columnsToGroupAccounts(columns));
    persisted.onColumnsChanged(columns);
  });

  const pinnedRowDataPipe = useAggDeltaUpdatesPipe({
    getUniqueKey: getBalanceKey,
    aggSpecs: balancesBlotterAggSpecs,
  });

  const handleRowSelectionChanged = useDynamicCallback((rows: BlotterTableRow<Balance>[]) =>
    setSelectedRows(compact(rows.map(row => row.data)))
  );

  const autoGroupColumnDef = useMemo(
    () =>
      columnTypes.group({
        type: 'group',
        title: 'Grouping',
        editable: false,
        hide: false,
        suppressColumnsToolPanel: false,
        params: {
          suppressCount: true,
        },
      }),
    []
  );

  const blotter = useBlotterTable<Balance>({
    dataObservable: balancesDeltaObs,
    pinnedRowDataPipe: pinnedRowDataPipe,
    showPinnedRows: true,
    rowID: 'rowID' satisfies keyof Balance,
    getExtraMainMenuItems,
    getContextMenuItems,
    sort: persisted.initialSort,
    columns: persisted.columns,
    onColumnsChanged: handleColumnsChanged,
    onSortChanged: persisted.onSortChanged,
    groupRowsSticky: true,
    groupDisplayType: 'singleColumn',
    rowSelection: 'multiple',
    onRowSelectionChanged: handleRowSelectionChanged,
    quickSearchParams: {
      entitySearchKeys: QUICK_FILTER_KEYS,
    },
    ...persistedRowGroupsOpened.blotterTableProps,
    ...({ groupRemoveSingleChildren: true, showOpenedGroup: true, autoGroupColumnDef } satisfies GridOptions),
  });

  const { expandGroupRow, scrollToRow, expandAllGroups, collapseAllGroups, setRowGroupColumns, removeRowGroupColumns } =
    blotter;

  const goToGroupRow = useDynamicCallback((key: string) => {
    expandGroupRow?.(key);
    // expandGroupRow isn't instant, so we want to place scrollToRow in the next render cycle to ensure the group we're after is expanded before we scroll there
    setTimeout(() => {
      scrollToRow?.(node => {
        if (node.group) {
          // For groups, the grouped data.property ends up in the key of the node. We can just use that.
          return node.key === key;
        }

        // For non-groups, we need to be aware of what showBy mode we're in and grab that off the data property ourselves since the ID is a rowID ("MarketAccountID-Currency" as string)
        const dataPropertyToCompare =
          state.showBy === 'counterparty' ? node.data?.marketAccountName : node.data?.Currency;
        return dataPropertyToCompare === key;
      }, 'top');
    }, 5);
  });

  useEffect(() => {
    setGoToGroupRow(() => goToGroupRow);
  }, [goToGroupRow, setGoToGroupRow]);

  const handleGroupAccountsToggle = useDynamicCallback((checked: boolean) => {
    if (checked) {
      setRowGroupColumns(['marketAccountGroup', 'marketAccountName'] satisfies (keyof Balance)[]);
    } else {
      removeRowGroupColumns(['marketAccountGroup'] satisfies (keyof Balance)[]);
    }
  });

  const handleShowZeroBalancesToggle = useDynamicCallback((checked: boolean) => {
    dispatch({
      type: TreasuryManagementActionType.ShowZeroBalancesChange,
      payload: {
        showZeroBalances: checked,
      },
    });
  });

  const handleExport = useDynamicCallback(() => {
    blotter.exportDataAsCSV({
      fileName: createCSVFileName({
        name: 'TreasuryManagement',
      }),
    });
  });

  return (
    <Box h={`calc(100% - ${EXPANDABLE_HEADER_HEIGHT}px)`}>
      <HStack
        justifyContent="space-between"
        gap="spacingComfortable"
        px="spacingDefault"
        h={ABOVE_BLOTTER_MENU_ROW_HEIGHT}
      >
        <AsOfDateBadge snapshotDate={state.snapshotDate} />
        <HStack justifyContent="flex-end" w="100%" gap="spacingSmall">
          <Input
            prefix={<Icon icon={IconName.Search} />}
            clearable={true}
            size={FormControlSizes.Small}
            width="120px"
            value={blotter.blotterTableFiltersProps.quickFilterText}
            onChange={e => blotter.blotterTableFiltersProps.onQuickFilterTextChanged(e.target.value)}
          />
          <Divider orientation="vertical" mx="spacingSmall" />
          <IconButton
            icon={IconName.ListExpand}
            size={FormControlSizes.Small}
            variant={ButtonVariants.Default}
            onClick={expandAllGroups}
            data-testid="expand-all-button"
          />
          <IconButton
            icon={IconName.ListCollapse}
            size={FormControlSizes.Small}
            variant={ButtonVariants.Default}
            onClick={collapseAllGroups}
          />
          <Divider orientation="vertical" mx="spacingSmall" />
          <Toggle
            tooltip={CONTROL_TOOLTIPS.showZeroBalances}
            size={FormControlSizes.Small}
            checked={state.showZeroBalances}
            onChange={handleShowZeroBalancesToggle}
            label="Show Zero Balances"
          />
          <Toggle
            disabled={state.showBy === 'asset'}
            size={FormControlSizes.Small}
            checked={groupAccounts}
            onChange={handleGroupAccountsToggle}
            label="Group Accounts"
          />
          <Divider orientation="vertical" mx="spacingSmall" />
          <Button startIcon={IconName.DocumentUpload} size={FormControlSizes.Small} onClick={handleExport}>
            Export
          </Button>
        </HStack>
      </HStack>
      <Divider />
      <VStack h={`calc(100% - ${ABOVE_BLOTTER_MENU_ROW_HEIGHT})`} w="100%">
        <Box px="spacingDefault" flex="1" w="100%">
          <BlotterTable {...blotter} />
        </Box>
        <BalancesBlotterSummaryLine rows={selectedRows} />
      </VStack>
    </Box>
  );
};

function columnsToGroupAccounts(columns: Column<any>[]): boolean {
  const groupColumn = columns.find(c => c.field === ('marketAccountGroup' satisfies keyof Balance));
  return !!groupColumn?.rowGroup;
}
