import type { GridOptions } from 'ag-grid-community';
import { cloneDeep, get, keys, mapValues } from 'lodash';
import { useCallback, useMemo, useRef, useState } from 'react';
import { map, pipe } from 'rxjs';
import { useUserContext } from '../../contexts';
import { useConstant, useDynamicCallback, useWSFilterPipe } from '../../hooks';
import { useGlobalToasts } from '../../providers';
import { DELETE, EMPTY_ARRAY, EMPTY_OBJECT, PATCH, POST, request } from '../../utils';
import {
  baseTreeGroupColumnDef,
  BlotterDensity,
  filterExistsAndExcludes,
  useAccordionFilterBuilder,
  useGenericFilter,
  usePersistedBlotterTable,
  type BlotterTableSort,
  type ColumnDef,
  type CompositePipeFunction,
  type UseBlotterTableProps,
} from '../BlotterTable';
import { useDrawer } from '../Drawer';
import type { FilterableProperty } from '../Filters';
import { NotificationVariants } from '../Notification';
import type { EntityAdminPageProps } from './EntityAdminPage';
import { ENTITY_INTERNAL_ROW_ID, EntityPageClass, type EntityPageRecord } from './types';
import {
  applyInheritanceCellStyle,
  getAddChildEntityColumn,
  getDeleteColumn,
  getEditColumn,
  getEntitiesByParentIDMap,
  getModeColumn,
  type HierarchicalColumnProps,
} from './utils';

export interface useEntityAdminPageProps<T extends EntityPageRecord>
  extends Omit<
    EntityAdminPageProps<T>,
    | 'blotterTable'
    | 'entityDrawer'
    | 'openEntityDrawer'
    | 'handleOnSaveEntity'
    | 'handleOnDeleteEntity'
    | 'handleOnUpdateEntity'
    | 'handleOnCreateNewEntity'
  > {
  /** The path for the GET API endpoint. */
  path?: string;

  /** The density of the table. */
  density?: BlotterDensity;

  /** Function to determine the POST path based on the entity. */
  getPostPath?: (entity: T) => string;

  /** Function to determine the PATCH or DELETE path based on the entity. */
  getPatchDeletePath?: (entity: T) => string;

  /** The name of the entity. */
  entityName?: string;

  /** The request API endpoint override. */
  apiEndpointOverride?: string;

  /** The columns to display in the table. If blank, all columns will be generated and displayed. */
  columns?: ColumnDef<T>[];

  /** The filter options to display in the table. */
  filterableProperties?: FilterableProperty[];

  /** Whether to allow mode switching. */
  allowModeSwitch?: boolean;

  /** Function to filter the list of entities. */
  filterFunc?: (entity: T) => boolean;

  /** The group column definition. */
  groupColumnDef?: GridOptions<EntityPageClass<T>>['autoGroupColumnDef'];

  /** The Blotter API ref */
  blotterTableApiRef?: React.MutableRefObject<{ refresh?: (force?: boolean) => void }>;
}

type UseEntityAdminPage<TData extends EntityPageRecord, TRow extends EntityPageClass<TData>> = Omit<
  EntityAdminPageProps<TData>,
  'blotterTable'
> & {
  blotterTableProps: Omit<UseBlotterTableProps<TRow>, 'dataObservable' | 'rowID'> & {
    rowID: string;
    startingColumns: ColumnDef<TData>[];
    endingColumns: ColumnDef<TData>[];
    // If we were stronger typed, this would be BlotterTableSort<TData>
    initialSort?: BlotterTableSort<TRow>;
    pipe?: CompositePipeFunction<TRow>;
  };
};

export const useEntityAdminPage = <T extends EntityPageRecord>(
  props: useEntityAdminPageProps<T>
): UseEntityAdminPage<T, EntityPageClass<T>> => {
  const {
    childIDField,
    entityIDField,
    filterableProperties = EMPTY_ARRAY,
    filterFunc: userFilterFunc = () => true,
    allowEditEntity = false,
    groupColumnDef: userTreeGroupColumnDef = EMPTY_OBJECT,
    columns: _columns = EMPTY_ARRAY,
    density = BlotterDensity.Comfortable,
    entitySearchKeys = EMPTY_ARRAY,
    path: getPath,
    getPostPath = (_: T) => getPath!, // If editing is allowed, this will never be undefined.
    getPatchDeletePath = (entity: T) => `${getPostPath(entity)}/${get(entity, entityIDField)}`,
    apiEndpointOverride,
    entityName = 'Entity',
    blotterTableApiRef,
    allowAddEntity = false,
    allowDeleteEntity = false,
    allowModeSwitch = false,
    addChildEntityButtonProps,
    persistKey = null,
  } = props;

  const [selectedEntity, setSelectedEntity] = useState<T | undefined>();
  const [addingChildEntity, setAddingChildEntity] = useState<boolean>(false);
  const [quickFilterText, setQuickFilterText] = useState<string>('');

  const { orgApiEndpoint } = useUserContext();
  const apiEndpoint = apiEndpointOverride ?? orgApiEndpoint;
  const { add: addToast } = useGlobalToasts();

  const entityDrawer = useDrawer({
    position: 'relative',
    width: 450,
    placement: 'right',
    closeOnEscape: true,
  });

  const openEntityDrawer: HierarchicalColumnProps<T>['openEntityDrawer'] = useDynamicCallback(
    (setupEntity: T | undefined, addingChildEntity: boolean) => {
      setSelectedEntity(setupEntity);
      setAddingChildEntity(addingChildEntity);
      entityDrawer.open();
    }
  );

  const onDoubleClickRow = useDynamicCallback((entity: EntityPageClass<T>) => {
    if (allowEditEntity) {
      openEntityDrawer(cloneDeep(entity.data), false);
    }
  });

  // AgGrid wants this return type to be a string[] but it's actually stronger typed as T[keyof T][]
  const getDataPath = useDynamicCallback((data: T): string[] => {
    // If the childIDField is provided, we treat the data as hierarchical.
    if (childIDField != null && get(data, childIDField)) {
      return [get(data, entityIDField), get(data, childIDField)] satisfies T[keyof T][] as string[];
    }
    return [get(data, entityIDField)] satisfies T[keyof T][] as string[];
  });

  const getUniqueKey = useDynamicCallback((entity: T) => getDataPath(entity).join('-'));

  const filterResults = useGenericFilter(filterableProperties);
  const filterBuilderAccordion = useAccordionFilterBuilder({
    accordionProps: { initialOpen: keys(filterResults.filter).length > 0 },
    filterBuilderProps: filterResults.filterBuilderProps,
  });

  const filterFunc = useCallback(
    (entity: T) => {
      let filteredOut = false;
      filterableProperties.forEach(property => {
        if (property.field == null) {
          throw new Error('Field is required for all filterable properties of EntityAdminPage');
        }
        if (filterExistsAndExcludes(filterResults.filter, property.key, entity, property.field as keyof T)) {
          filteredOut = true;
        }
      });
      return !filteredOut;
    },
    [filterResults.filter, filterableProperties]
  );

  const filterPipe = useWSFilterPipe<T>({ getUniqueKey, filterFunc });
  const parentMapRef = useRef<Map<keyof T, T> | undefined>();
  const blotterTablePipe: CompositePipeFunction<EntityPageClass<T>, T> = useConstant(
    pipe(
      filterPipe,
      map(json => {
        parentMapRef.current = getEntitiesByParentIDMap(json.data, entityIDField, childIDField);
        return json;
      }),
      map(json => ({
        ...json,
        data: json.data
          .filter(item => userFilterFunc(item))
          .map(row => new EntityPageClass<T>(cloneDeep(row), entityIDField, childIDField, parentMapRef.current)),
      }))
    )
  );

  const treeDataProps: Omit<GridOptions<EntityPageClass<T>>, 'onSortChanged'> | undefined = useMemo(() => {
    if (childIDField != null) {
      // If the childIDField is provided, we treat the data as hierarchical.
      return {
        autoGroupColumnDef: { ...baseTreeGroupColumnDef, ...userTreeGroupColumnDef },
        treeData: true,
        getDataPath: (entity: EntityPageClass<T>) => {
          return getDataPath(entity.data);
        },
      };
    }
    return undefined;
  }, [childIDField, getDataPath, userTreeGroupColumnDef]);

  const styledColumns = useMemo(() => {
    if (childIDField != null) {
      // If the childIDField is provided, we treat the data as hierarchical.
      return _columns?.map(applyInheritanceCellStyle);
    }
    return _columns;
  }, [_columns, childIDField]);

  const getPathWithApiEndpoint = useDynamicCallback((path: string) => `${apiEndpoint}${path}`);

  const postEntity = useDynamicCallback((entity: T) =>
    request<T>(POST, getPathWithApiEndpoint(getPostPath(entity)), entity)
  );
  const patchEntity = useDynamicCallback((entity: T) =>
    request(PATCH, getPathWithApiEndpoint(getPatchDeletePath(entity)), entity)
  );
  const deleteEntity = useDynamicCallback((entity: T) =>
    request(DELETE, getPathWithApiEndpoint(getPatchDeletePath(entity)))
  );

  const handleOnUpdateEntity = useDynamicCallback((updatedEntity: T) => {
    // Convert empty strings to null
    const entityForPatch: T = mapValues(cloneDeep(updatedEntity), value => (value === '' ? null : value)) as T;

    return patchEntity(entityForPatch)
      .then((res: T) => {
        entityDrawer.close();
        blotterTableApiRef?.current.refresh?.();
        addToast({
          text: `${entityName} entity saved successfully.`,
          variant: NotificationVariants.Positive,
        });
        return res;
      })
      .catch(error => {
        addToast({
          text: error.toString() ?? `Failed to save ${entityName} entity.`,
          variant: NotificationVariants.Negative,
        });
        return Promise.reject();
      });
  });

  const handleOnDeleteEntity = useDynamicCallback((selectedEntity: T) => {
    return deleteEntity(selectedEntity)
      .then((res: T) => {
        entityDrawer.close();
        blotterTableApiRef?.current.refresh?.(true);
        addToast({
          text: 'Entity deleted successfully.',
          variant: NotificationVariants.Positive,
        });
        return res;
      })
      .catch(error => {
        addToast({
          text: error.toString() ?? 'Failed to delete entity',
          variant: NotificationVariants.Negative,
        });
        return Promise.reject();
      });
  });

  const handleOnCreateNewEntity = useDynamicCallback((newEntity: T) => {
    return postEntity(newEntity)
      .then((res: T) => {
        entityDrawer.close();
        blotterTableApiRef?.current.refresh?.();
        addToast({
          text: `${entityName} entity created successfully.`,
          variant: NotificationVariants.Positive,
        });
        return res;
      })
      .catch(error => {
        addToast({
          text: error.toString() ?? `Failed to create ${entityName} entity.`,
          variant: NotificationVariants.Negative,
        });
        return Promise.reject();
      });
  });

  const handleOnSaveEntity = useDynamicCallback((entity: T) => {
    if (selectedEntity == null || addingChildEntity) {
      return handleOnCreateNewEntity(entity);
    } else {
      return handleOnUpdateEntity(entity);
    }
  });

  const startingColumns = useMemo(() => {
    const colDefs: ColumnDef<T>[] = [];

    if (allowModeSwitch) {
      colDefs.push(
        getModeColumn<T>({
          handleOnClick: (entity: T) => handleOnUpdateEntity(entity),
        })
      );
    }

    return colDefs;
  }, [allowModeSwitch, handleOnUpdateEntity]);

  const endingColumns = useMemo(() => {
    const colDefs: ColumnDef<T>[] = [];

    if (allowAddEntity && childIDField != null) {
      colDefs.push(
        getAddChildEntityColumn<T>({
          openEntityDrawer: openEntityDrawer,
          buttonProps: addChildEntityButtonProps,
          entityIDField: entityIDField,
          childIDField: childIDField,
        })
      );
    }

    if (allowEditEntity) {
      colDefs.push(
        getEditColumn<T>({
          handleOnClick: (entity: T) => {
            openEntityDrawer(entity, false);
          },
        })
      );
    }

    if (allowDeleteEntity) {
      colDefs.push(
        getDeleteColumn<T>({
          handleOnClick: (entity: T) => {
            if (window.confirm('Are you sure you want to delete this entity?')) {
              handleOnDeleteEntity(entity);
            }
          },
        })
      );
    }

    return colDefs;
  }, [
    addChildEntityButtonProps,
    allowAddEntity,
    allowDeleteEntity,
    allowEditEntity,
    childIDField,
    entityIDField,
    handleOnDeleteEntity,
    openEntityDrawer,
  ]);

  const persistedTable = usePersistedBlotterTable<T>(persistKey, {
    columns: styledColumns,
    persistColumns: persistKey != null,
    persistFilter: persistKey != null,
    persistSort: persistKey != null,
  });

  const value = useMemo(() => {
    return {
      blotterTableProps: {
        rowID: ENTITY_INTERNAL_ROW_ID,
        density,
        // We need this casting due to the way "pipe" is typed.
        pipe: blotterTablePipe as CompositePipeFunction<EntityPageClass<T>, unknown>,
        quickSearchParams: {
          // We need this casting due to the way "quickSearchParams" is typed.
          entitySearchKeys: entitySearchKeys as (keyof EntityPageClass<T>)[],
          filterText: quickFilterText,
        },
        onDoubleClickRow,
        startingColumns,
        endingColumns,
        rowSelection: 'single',
        ...treeDataProps,
        ...persistedTable,
        // We need this casting due to the way "persistedTable" is typed.
        initialSort: persistedTable.initialSort as BlotterTableSort<EntityPageClass<T>>,
      },
      childIDField,
      blotterTableFilters: {
        ...filterBuilderAccordion,
        onQuickFilterTextChanged: setQuickFilterText,
      },
      entityDrawer,
      openEntityDrawer,
      handleOnSaveEntity,
      handleOnDeleteEntity,
      handleOnUpdateEntity,
      handleOnCreateNewEntity,
      addingChildEntity,
      selectedEntity,
      ...props,
    } satisfies UseEntityAdminPage<T, EntityPageClass<T>>;
  }, [
    addingChildEntity,
    blotterTablePipe,
    childIDField,
    density,
    endingColumns,
    entityDrawer,
    entitySearchKeys,
    filterBuilderAccordion,
    handleOnCreateNewEntity,
    handleOnDeleteEntity,
    handleOnSaveEntity,
    handleOnUpdateEntity,
    onDoubleClickRow,
    openEntityDrawer,
    persistedTable,
    props,
    quickFilterText,
    selectedEntity,
    startingColumns,
    treeDataProps,
  ]);

  return value;
};
