import styled, { css, type DefaultTheme } from 'styled-components';

import { darken, lighten, transparentize } from 'polished';
import { hoverColor, parseColor, setAlpha } from '../../styles';
import { WARNING_ROW_CLASSNAME } from '../AgGrid/types';
import { Button } from '../Button';
import { Box, VStack } from '../Core';
import { FormRowStatus } from '../FormTable/types';
import { NumberWrapper } from '../FormattedNumber';
import { BlotterDensity } from './types';

const getFontSize = (theme: DefaultTheme, density?: BlotterDensity) =>
  density === BlotterDensity.Compact ? theme.fontSizeBlotterTableCompact : theme.fontSizeBlotterTableDefault;

const getSpacing = (theme: DefaultTheme, density?: BlotterDensity) =>
  density === BlotterDensity.Compact
    ? theme.spacingSmall
    : density === BlotterDensity.Comfortable
    ? theme.spacingMedium
    : theme.spacingDefault;

export const blotterStyles = (
  theme: DefaultTheme,
  density?: BlotterDensity,
  background?: string,
  alwaysShowColumnDividers?: boolean
) => {
  const parsedColor = background && background !== 'transparent' ? parseColor(theme, background) : undefined;
  const backgroundColor = parsedColor ? parseColor(theme, parsedColor) : theme.backgroundBlotterTableRow;
  const hoverBackgroundColor = parsedColor ? hoverColor(theme, parsedColor) : theme.backgroundBlotterTableRowHover;
  const borderColor = parsedColor ? darken(0.05, parsedColor) : theme.borderColorBlotterTable;

  const { dividerColor, dividerCss: columnDividerCss } = calculateDividerStyles(theme, !alwaysShowColumnDividers);

  return css`
    font-family: ${theme.fontFamily};
    font-size: ${getFontSize(theme, density)}rem;
    height: 100%; // Combats Sticky Headers not working, by allowing us to remove domLayout="autoHeight"

    // Root
    --ag-size: 3px; // Not working?
    --ag-borders: false; // Still leaves border around table
    --ag-border-radius: 0px;
    --ag-foreground-color: ${theme.colorTextImportant};
    --ag-data-color: ${theme.colorTextImportant};
    --ag-secondary-foreground-color: ${theme.colorTextSubtle};
    --ag-disabled-foreground-color: ${theme.colorTextMuted};
    --ag-background-color: ${backgroundColor};
    --ag-font-size: ${getFontSize(theme, density)}rem;
    --ag-borders-secondary: false;
    --ag-header-column-separator-color: ${dividerColor};
    --hoverBackgroundColor: ${hoverBackgroundColor};

    // Hide border around grid
    .ag-root-wrapper {
      border: 0;
    }

    // Allow elements in NoRowsOverlay to be clicked
    .ag-overlay {
      background: var(--ag-background-color);
      pointer-events: all;
    }

    .ag-tooltip-custom {
      position: absolute;
    }

    .ag-tooltip {
      max-width: 25rem;
    }

    // Header
    --ag-header-foreground-color: ${theme.colorBlotterTableHeader};
    --ag-header-background-color: ${backgroundColor};
    --ag-border-color: ${borderColor};
    --ag-subheader-background-color: transparent;
    --ag-subheader-toolbar-background-color: transparent;
    --ag-header-cell-moving-background-color: ${hoverBackgroundColor};

    .ag-header-cell.hide-header-label {
      .ag-header-cell-text {
        visibility: hidden;
      }
    }

    .ag-header-cell,
    .ag-header-group-cell,
    .ag-header-cell-group {
      font-size: ${theme.fontSizeBlotterTableHeader}rem;
      text-transform: ${theme.blotterTableHeaderTextTransform};
      font-weight: ${({ theme }) => theme.blotterTableHeaderFontWeight};
      padding: 0 ${getSpacing(theme, density)}px;

      &.ag-header-cell-sortable:hover {
        color: ${({ theme }) => theme.colorTextImportant};
      }
      .ag-header-cell-menu-button {
        cursor: pointer;
        color: ${({ theme }) => theme.colorTextImportant};
      }
    }

    .ag-header-cell-sorted-desc,
    .ag-header-cell-sorted-asc {
      color: ${({ theme }) => theme.colorTextImportant};
    }

    // For columns that are not sortable, hide the sort arrow since they render anyway (bug in ag-grid)
    .ag-custom-column-group:not(.ag-header-cell-sortable) {
      .hi-arrow-up,
      .hi-arrow-down {
        visibility: hidden;
      }
    }

    .ag-header-cell.no-handle:after {
      display: none;
    }

    // Remove left side border of right pinned cells
    .ag-pinned-right-header {
      border-left: transparent;
    }

    // Remove right side border of left pinned cells
    .ag-pinned-left-header {
      border-right: transparent;
    }

    // Show column divider on hover, before and after
    ${columnDividerCss}

    .ag-header-cell {
      .ag-header-cell-menu-button {
        flex: 0 1 1px;
        overflow: hidden;
        transition: flex 200ms ease 500ms, opacity 200ms ease 500ms;
        opacity: 0;
      }

      &.ag-header-active,
      &.ag-column-menu-visible {
        .ag-header-cell-menu-button {
          flex: 0 0 16px;
          opacity: 1;
          transition: flex 200ms ease, opacity 200ms ease;
        }
      }
    }

    .ag-keyboard-focus {
      .ag-header-cell {
        &:hover:after,
        &:hover:before {
          background-color: transparent;
        }
      }
    }

    .ag-header-no-title {
      .ag-header-cell-comp-wrapper {
        height: 100%;
        display: flex;
        justify-content: center;
      }
      .ag-header-cell-text {
        display: none;
      }
      padding: 0;
      .ag-header-cell-label .ag-sort-indicator-icon {
        margin-left: 0;
        padding-left: 0;
      }
    }

    // This can be referenced by ColDef.headerClass.
    // Used for when you want to render a custom talos <Checkbox> component yourself in an aggrid header cell.
    .ag-header-custom-checkbox {
      .ag-header-cell-text {
        display: none;
      }
      padding: 0;
    }

    .ag-column-drop-horizontal {
      background-color: inherit;
    }

    .ag-column-drop-cell {
      background: ${theme.backgroundDefaultButton};
      height: 20px;
      color: ${theme.colorTextDefaultButton};
    }

    // Rows
    --ag-row-border-color: ${borderColor};
    --ag-row-hover-color: ${hoverBackgroundColor};

    --ag-odd-row-background-color: ${backgroundColor
      ? setAlpha(0.5, backgroundColor)
      : theme.backgroundBlotterTableRow};
    --ag-value-change-value-highlight-background-color: ${theme.backgroundPrimarySubtle};
    .ag-row {
      transition: background 250ms;

      &.ag-row-hover.ag-row-selected::before {
        background-image: none;
      }
    }

    // Imitate a filler column at the end to cover the entire width
    .ag-center-cols-container {
      min-width: 100%;
    }

    .ag-row.${FormRowStatus.Added}, .ag-row.${FormRowStatus.Added}:hover {
      background: ${theme.backgroundPositiveMuted};
      font-style: italic;
      opacity: 1;
    }

    .ag-row.${FormRowStatus.Updated}, .ag-row.${FormRowStatus.Updated}:hover {
      background: ${theme.backgroundPrimaryMuted};
      font-style: italic;
      opacity: 1;
    }

    .ag-row.${FormRowStatus.Removed}, .ag-row.${FormRowStatus.Removed}:hover {
      background: ${theme.backgroundNegativeMuted};
      font-style: italic;
      opacity: 1;
    }

    // Widgets
    --ag-modal-overlay-background-color: ${(typeof background === 'string'
      ? parseColor(theme, background)
      : background) ?? theme.backgroundContent};

    // Groups
    .ag-row-group {
      align-items: center;
    }

    .ag-cell,
    .ag-header-cell {
      &.ag-center-aligned-cell .ag-header-cell-label,
      &.ag-center-aligned-cell,
      &.ag-center-aligned-cell .ag-react-container {
        justify-content: center;
      }
      &.ag-right-aligned-cell .ag-header-cell-label,
      &.ag-right-aligned-cell,
      &.ag-right-aligned-cell .ag-react-container {
        justify-content: flex-end;
      }
      &.ag-custom-column-group {
        background: ${({ theme }) => theme.colors.gray['030']};
      }
    }

    --ag-range-selection-border-color: transparent;
    --ag-selected-row-background-color: ${theme.backgroundBlotterTableRowSelected};
    .ag-cell {
      // - 1 to take invisible border into account
      padding: 0 ${getSpacing(theme, density) - 1}px;
      line-height: 1;

      overflow: hidden;
      display: flex;
      align-items: center;

      // For number cells that are aligned right
      &.ag-right-aligned-cell {
        :has(> ${NumberWrapper}) {
          &:hover {
            overflow: visible;
            ${NumberWrapper} {
              // show the full number on hover, with a background color as well as some spacing to the left
              // to ensure that (any possible) number to the left bleed into this cell.
              padding-left: ${theme.spacingTiny}px;
              background-color: var(--hoverBackgroundColor);
            }
          }
        }
      }

      // Remove left side border of right pinned cells
      &.ag-cell-first-right-pinned:not(.ag-cell-range-left):not(.ag-cell-range-single-cell) {
        border-left: transparent;
      }

      // Remove right side border of left pinned cells
      &.ag-cell.ag-cell-last-left-pinned:not(.ag-cell-range-right):not(.ag-cell-range-single-cell) {
        border-right: transparent;
      }

      // Increase left padding for first cell in a row
      .ag-cell-value[aria-colindex='1'],
      .ag-header-cell[aria-colindex='1'] {
        padding-left: ${({ theme }) => theme.spacingMedium}px;
      }

      &.editable {
        transition: background 150ms;

        &:hover {
          background: ${theme.colors.gray['030']};
          cursor: pointer;
        }
      }

      &.invalid {
        border-color: ${({ theme }) => theme.colors.red.lighten};
      }

      // Allow cell values to overflow when hovering to support showing all decimals of a number
      .ag-cell-value:hover {
        overflow: visible;
        z-index: 1;
      }

      // Allow InlineFormattedNumber to inherit background when hovering
      .${NumberWrapper} {
        background: inherit;
      }
    }

    // Checkbox styling
    --ag-checkbox-checked-color: ${theme.colors.white.default};
    --ag-checkbox-indeterminate-color: ${theme.colors.white.default};
    --ag-checkbox-unchecked-color: ${theme.colorTextDefault};
    .ag-checkbox-input-wrapper {
      box-shadow: none;
      border: 1px solid ${theme.borderColorCheckbox};
      background: ${theme.backgroundCheckbox};

      &:focus-within {
        border: 1px solid ${theme.borderColorCheckboxFocus};
      }

      &:hover {
        background: ${theme.backgroundCheckboxHover};
      }

      &.ag-checked {
        background: ${({ theme }) => theme.backgroundCheckboxChecked};
        border: 1px solid ${({ theme }) => theme.borderColorCheckboxChecked};
        &:after {
          opacity: 1;
        }
      }

      &.ag-indeterminate {
        background: ${({ theme }) => theme.backgroundCheckboxChecked};
        border: 1px solid ${({ theme }) => theme.borderColorCheckboxChecked};
        &:after {
          content: '\\e996';
          opacity: 1;
        }
      }

      &:after {
        font-family: helixicons;
        content: '\\e92b';
        font-size: ${({ theme }) => theme.fontSizeDefault}rem;
        opacity: 0;
        transition: opacity 200ms ease;
      }
    }

    // Gradient for pinned column to indicate more content
    .ag-pinned-right-cols-container {
      box-shadow: 0 0 15px ${({ theme }) => backgroundColor}, 0 0 3px ${({ theme }) => backgroundColor};
    }
    .ag-pinned-right-header {
      box-shadow: 0 0 15px ${({ theme }) => backgroundColor}, 0 0 3px ${({ theme }) => backgroundColor};
    }

    // Inputs
    .ag-react-container:not(.ag-tooltip-custom) {
      display: flex;
      align-items: center;
      height: 100%;
      width: 100%;
    }

    .${WARNING_ROW_CLASSNAME} {
      background-color: ${({ theme }) => transparentize(1 - 0.12, theme.colors.red.default)};
      --ag-row-hover-color: ${({ theme }) => transparentize(1 - 0.24, theme.colors.red.default)};
      --ag-selected-row-background-color: ${({ theme }) => transparentize(1 - 0.36, theme.colors.red.default)};
    }

    .ag-cell-warning {
      padding-left: 0;
      padding-right: 0;
      > .ag-react-container {
        align-items: stretch;
      }
    }

    .ag-cell-inline-editing {
      border: none;
      background: transparent;
      box-shadow: none;
      height: auto;
      padding: 0;
    }

    // Context menu
    .ag-menu {
      background: ${({ theme }) => theme.backgroundPopover};
    }

    .ag-rich-select .ag-rich-select-list {
      width: auto !important;
    }
  `;
};

export const BlotterTableWrapper = styled.div<{
  density?: BlotterDensity;
  background?: string;
  pinnedLeftOverLapping?: boolean;
  alwaysShowColumnDividers?: boolean;
}>`
  height: 100%;
  width: 100%;

  p {
    color: ${({ theme }) => theme.colorTextAttention};
    margin: 0;
    padding: 0;
  }

  &.ag-theme-balham-dark {
    // See https://www.ag-grid.com/javascript-grid/themes-customising/#full-list-of-theme-parameters

    ${({ theme, density, background, alwaysShowColumnDividers }) =>
      blotterStyles(theme, density, background, alwaysShowColumnDividers)}
  }
  // It is possible to solve this for right-pinned items too, but it is error-prone
  // and not part of the current requirements.
  // https://stackoverflow.com/q/73910201/11076115
  ${({ pinnedLeftOverLapping, theme }) =>
    pinnedLeftOverLapping &&
    css`
      // Gradient for pinned column to indicate more content
      .ag-pinned-left-cols-container {
        box-shadow: 0 0 15px ${theme.backgroundBlotterTableRow}, 0 0 3px ${theme.backgroundBlotterTableRow};
        z-index: 1;
      }
      .ag-pinned-left-header {
        box-shadow: 0 0 15px ${theme.backgroundBlotterTableRow}, 0 0 3px ${theme.backgroundBlotterTableRow};
      }
    `}
`;

export const CustomerRolePillWrapper = styled(Box)`
  background: ${({ theme }) => theme.colors.gray['030']};
  padding: ${({ theme }) => `${theme.spacingSmall}px ${theme.spacingDefault}px`};
  border-radius: ${({ theme }) => theme.borderRadiusDefault}px;
  white-space: nowrap;
`;

export const BlotterMenuWrapper = styled(VStack)`
  align-items: flex-start;
  gap: ${({ theme }) => theme.spacingDefault}px;

  ${Button} {
    justify-content: flex-start;
    width: 100%;
  }
`;

/**
 * Calculate the divider styles for the Grid column dividers
 */
function calculateDividerStyles(
  theme: DefaultTheme,
  showDividersOnHover: boolean | undefined
): {
  /** The color of the divider (used in the base Ag-grid styles) */
  dividerColor: string;
  /** The css for the column divider hover behavior */
  dividerCss: ReturnType<typeof css> | string;
} {
  // theme.borderColorDivider is the divider color used outside the grid
  const baseDividerColor = theme.borderColorDivider;
  const strengthenAmount = showDividersOnHover ? 0.2 : 0.1;
  const dividerColor =
    theme.type === 'dark' ? lighten(strengthenAmount, baseDividerColor) : darken(strengthenAmount, baseDividerColor);

  const columnDividerCss = showDividersOnHover
    ? css`
        .ag-header-cell,
        .ag-header-group-cell {
          // overflow: visible and :before left: -1px allows the left
          // divider to visually overlap the previous column
          overflow: visible;
          &:before {
            left: -1px;
          }
          &:after {
            right: 0;
          }
          &:before,
          &:after {
            content: '';
            position: absolute;
            z-index: 1;
            display: block;
            width: 1px;
            height: 50%;
            top: 25%;
            background-color: transparent;
          }
          &:hover:before,
          &:hover:after {
            background-color: ${theme.type === 'dark' ? lighten(0.2, dividerColor) : darken(0.2, dividerColor)};
          }
        }
      `
    : '';
  return { dividerColor: dividerColor, dividerCss: columnDividerCss };
}
