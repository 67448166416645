import { createSelector, createSlice, type PayloadAction } from '@reduxjs/toolkit';
import {
  AllocationValueTypeEnum,
  BaseField,
  DateTimeDurationPickerValueType,
  FeeModeEnum,
  Field,
  ManualStrategy,
  MultiSelectorField,
  NumericField,
  OrdTypeEnum,
  ProductTypeEnum,
  SelectorField,
  SideEnum,
  Unit,
  canCcyFutureOrPerp,
  getScaleFromIncrement,
  isFuture,
  isPerpetualSwap,
  isSecurityQuantoFuture,
  isSpot,
  type Customer,
  type CustomerBalance,
  type CustomerOrder,
  type CustomerSpecificSecurity,
  type DateField,
  type MarketAccount,
  type OrderStrategy,
  type Security,
  type StrategyLike,
} from '@talos/kyoko';
import type { WritableDraft } from 'immer';
import type { AppState } from 'providers/AppStateProvider/types';
import { isOrderTypeLimitAllIn } from 'utils/security';
import { getDefaultMarketAccountForCustomer, getQuantityIncrement, setGlobalSymbol } from '../Common';
import { initialRefDataState } from '../OMSReferenceDataSlice';
import type { OMSReferenceDataState } from '../types';
import { getNormalizedOrderQty, getOrdType } from '../utils';
import { validateOrderMarkets } from './FieldRules';
import type { CustomerPreviewResponse, SalesOrderDependencies, SalesOrderInitPayload, SalesOrderState } from './types';
import { initializeStrategyParams } from './utils';

export const getInitialState = (): SalesOrderState => ({
  dependencies: {
    showAllInPrices: FeeModeEnum.Maker,
    customerBalances: [],
  },
  referenceData: initialRefDataState,
  form: {
    customerField: new SelectorField({ idProperty: 'Name', name: 'Customer' }),
    customerAccountField: new SelectorField({ idProperty: 'Name', name: 'Account' }),
    symbolField: new SelectorField({ idProperty: 'Symbol', name: 'Symbol' }),
    sideField: new Field({ value: SideEnum.Buy }),
    customerStrategyField: new SelectorField({
      idProperty: 'Name',
      name: 'Strategy',
    }),
    customerQuantityField: new NumericField({ name: 'Quantity' }),
    orderCurrencyField: new Field(),
    customerSpreadField: new NumericField({ name: 'Customer Spread', unit: Unit.Bps, isRequired: false }),
    parameters: {},
    useDefaultAggregationField: new Field({ value: true }),
    principalQuantityField: new NumericField({ name: 'Quantity', isDisabled: true, scale: undefined }),
    principalOrderCurrencyField: new Field({ isDisabled: true }), // this is derived from the preview, do not set enabled
    principalStrategyField: new SelectorField({
      idProperty: 'Name',
      name: 'Strategy',
      isDisabled: true,
      placeholder: 'Select Strategy',
    }),
    principalPriceField: new NumericField({ name: 'Price', isDisabled: true, scale: undefined }),
    principalGroupField: new Field({ name: 'Group', isRequired: false }),
    principalCommentsField: new Field({ name: 'Comment', isRequired: false }),
    principalParameters: {},
    allocationValueTypeField: new Field({ value: AllocationValueTypeEnum.Percentage, isDisabled: true }),
    allocations: [],
    orderMarketsField: new MultiSelectorField(),
  },
  defaultBidOfferSpreads: { bid: '', offer: '' },
  isLoading: false,
  orderBeingModified: undefined,
  customerInfoSectionExpanded: true,
  isPreviewing: false,
  initialized: false,
  symbolToSupportedStrategyMap: new Map(),
});

/**
 unlike resetState which is called when we close the order form and reset every state property back to default,
 clean only resets part of the state. e.g. needed for when we go from modifying an order and midway deciding to
 resubmitting another - so we still need to keep certain state (settings/refData) but clean enough to
 transition into another order workflow essentially
 */
const cleanFormState = (state: WritableDraft<SalesOrderState>) => {
  state.form = getInitialState().form;
  state.orderBeingModified = undefined;
  state.isPreviewing = false;
  state.defaultBidOfferSpreads = { bid: '', offer: '' };
  state.customerInfoSectionExpanded = true;
  state.symbolToSupportedStrategyMap = new Map();
};

const initializeFieldsFromOrder = (state: WritableDraft<SalesOrderState>, order: CustomerOrder, isModify = false) => {
  state.form.customerField = state.form.customerField.setDisabled(isModify).updateValueFromID(order.Counterparty, true);
  handleCustomerChange(state, state.form.customerField.value);

  state.form.customerAccountField = state.form.customerAccountField
    .setDisabled(isModify)
    .updateValueFromID(order.MarketAccount, true);
  state.form.symbolField = state.form.symbolField.setDisabled(isModify).updateValueFromID(order.Symbol, true);
  handleSymbolChange(state, state.form.symbolField.value);

  state.form.sideField = state.form.sideField
    .setDisabled(isModify)
    .updateValue(order.Side === SideEnum.Buy ? SideEnum.Buy : SideEnum.Sell, true);

  state.form.customerStrategyField = state.form.customerStrategyField
    .setDisabled(isModify)
    // TODO: Change the type of order.
    // The order is actually of type Order, not CustomerOrder. This is a bug in the types.
    // In the Order, customerStrategy is the customer order, order.Strategy is the hedge / preview.
    .updateValueFromID(order['customerStrategy'] ?? order.Strategy, true);

  state.form.orderCurrencyField = state.form.orderCurrencyField.setDisabled(isModify).updateValue(order.Currency, true);

  state.form.customerQuantityField = state.form.customerQuantityField
    .updateValue(order.OrderQty, true)
    .updateScale(getQuantityIncrement(state.form.symbolField.value, order.Currency));

  const spreadField =
    order.Side === SideEnum.Buy ? order.PricingParameters?.OfferSpread : order.PricingParameters?.BidSpread;

  state.form.customerSpreadField = state.form.customerSpreadField
    .updateValue(spreadField || order.PricingParameters?.Spread, true)
    .setTouched(true); // if we don't set touched to true system will override it with default value

  state.form.parameters = initializeStrategyParams({
    security: state.form.symbolField.value,
    strategy: state.form.customerStrategyField.value,
    isModify,
  });
  Object.keys(state.form.parameters).forEach(key => {
    state.form.parameters[key] = state.form.parameters[key].updateValue(order.Parameters?.[key], true);
  });
  if (order.Price && state.form.parameters['LimitPrice']) {
    // special mapping case due to inconsistent API
    state.form.parameters['LimitPrice'] = state.form.parameters['LimitPrice'].updateValue(order.Price, true);
  }
  if (state.form.parameters.StartTime && state.form.parameters.EndTime) {
    state.form.parameters.EndTime = (state.form.parameters.EndTime as DateField).setRelativeDate({
      type: DateTimeDurationPickerValueType.DateTime,
      value: state.form.parameters.StartTime.value,
    });
  }
  if (order.PricingParameters?.PricingMarkets) {
    state.form.orderMarketsField = state.form.orderMarketsField.updateValue(
      order.PricingParameters?.PricingMarkets.map(o => o.MarketAccount)
    );
    state.form.useDefaultAggregationField = state.form.useDefaultAggregationField.updateValue(false);
  }
};

const populateDropdownsFromRefData = (state: WritableDraft<SalesOrderState>) => {
  const { customers, strategies, customerMarketAccounts } = state.referenceData;
  state.form.customerField = state.form.customerField.updateAvailableItems(customers);
  state.form.customerAccountField = state.form.customerAccountField.updateAvailableItems(customerMarketAccounts);
  state.form.principalStrategyField = state.form.principalStrategyField.updateAvailableItems(strategies.strategiesList);
};

const handleCustomerChange = (state: WritableDraft<SalesOrderState>, customer?: Customer) => {
  if (customer) {
    state.form.customerAccountField = getDefaultMarketAccountForCustomer(
      state.form.customerAccountField,
      state.referenceData.customerMarketAccounts,
      customer
    );
  }
};

const handleSymbolChange = (state: WritableDraft<SalesOrderState>, security?: Security) => {
  if (security) {
    state.form.orderCurrencyField = state.form.orderCurrencyField.updateValue(security?.PositionCurrency, true);
    state.form.customerQuantityField = state.form.customerQuantityField
      .updateValue(getNormalizedOrderQty(security, state.form.orderCurrencyField.value), true)
      .updateScale(getQuantityIncrement(security, security?.PositionCurrency));

    initializeStrategy(state, security);
  }

  updatePrincipalLimitPriceScale(state);
  updatePrincipalQuantityScale(state);

  state.form.customerSpreadField = getInitialState().form.customerSpreadField;
  clearOrderMarkets(state);
};

const initializeStrategy = (state: WritableDraft<SalesOrderState>, security: Security) => {
  state.form.customerStrategyField = state.form.customerStrategyField.updateAvailableItems(
    state.symbolToSupportedStrategyMap.get(security.Symbol) || []
  );

  if (!state.form.customerStrategyField.value) {
    const limitStrategyOrFirst =
      state.form.customerStrategyField.availableItems.find(s => s.Name === 'Limit') ||
      state.form.customerStrategyField.availableItems[0];
    state.form.customerStrategyField = state.form.customerStrategyField.updateValue(limitStrategyOrFirst, true);
  }

  state.form.parameters = initializeStrategyParams({
    security,
    strategy: state.form.customerStrategyField.value,
    isModify: false,
  });
};

const validateUseAggregationField = (state: WritableDraft<SalesOrderState>) => {
  state.form.useDefaultAggregationField = state.form.useDefaultAggregationField.validate(
    [validateOrderMarkets],
    state.form
  );
};

const clearOrderMarkets = (state: WritableDraft<SalesOrderState>) => {
  state.form.useDefaultAggregationField = state.form.useDefaultAggregationField.updateValue(true);
  state.form.orderMarketsField = state.form.orderMarketsField.updateValue([]);
};

export const salesOrderSlice = createSlice({
  name: 'salesOrder',
  initialState: getInitialState(),
  reducers: {
    setReferenceData: (state, action: PayloadAction<OMSReferenceDataState>) => {
      state.referenceData = action.payload;

      // handle the case where we launched the form so quickly before the app received it's first ref data tick
      if (state.initialized && state.form.customerField.availableItems.length === 0) {
        populateDropdownsFromRefData(state);
      }
    },
    onMount: (state, action: PayloadAction<SalesOrderInitPayload>) => {
      if (!state.initialized) {
        state.initialPayload = action.payload;
        populateDropdownsFromRefData(state);

        const initialCustomer = state.form.customerField.availableItems.find(
          c => c.CounterpartyID === action.payload.initialSelectedCustomerID
        );
        if (initialCustomer) {
          state.form.customerField = state.form.customerField.updateValue(initialCustomer, true);
          handleCustomerChange(state, initialCustomer);
        }
      }
      state.initialized = true;
    },
    setDependencies: (state, action: PayloadAction<SalesOrderDependencies>) => {
      state.dependencies.showAllInPrices = action.payload.showAllInPrices;
      state.dependencies.customerBalances = action.payload.customerBalances;
    },
    setCustomerSecuritiesAndStrategies: (
      state,
      action: PayloadAction<{
        availableSecurities: (Security & CustomerSpecificSecurity)[];
        symbolToSupportedStrategyMap: Map<string, StrategyLike[]>;
      }>
    ) => {
      state.form.symbolField = state.form.symbolField.updateAvailableItems(action.payload.availableSecurities, {
        preventDefaultToValue: true,
      });
      state.symbolToSupportedStrategyMap = action.payload.symbolToSupportedStrategyMap;

      // ensure strategies are properly initialized based on the selected security
      if (
        state.form.customerStrategyField.availableItems.length === 0 &&
        (state.form.symbolField.value?.SupportedStrategies.length || action.payload.availableSecurities.length === 1)
      ) {
        state.form.symbolField = state.form.symbolField.updateValue(action.payload.availableSecurities[0]);
        handleSymbolChange(state, state.form.symbolField.value);
      }
    },
    setCustomer: (state, { payload: customer }: PayloadAction<Customer>) => {
      state.form.customerField = state.form.customerField.updateValue(customer);
      handleCustomerChange(state, customer);
    },
    setCustomerAccount: (state, action: PayloadAction<MarketAccount>) => {
      state.form.customerAccountField = state.form.customerAccountField.updateValue(action.payload);
    },
    setSymbol: (state, action: PayloadAction<string | undefined>) => {
      const security = state.form.symbolField.availableItems.find(s => s.Symbol === action.payload);
      state.form.symbolField = state.form.symbolField.updateValue(security);

      // Automatically collapse customer info section if customer section is all filled
      if (state.form.symbolField.value && state.form.customerField.value && state.form.customerAccountField.value) {
        state.customerInfoSectionExpanded = false;
      }

      handleSymbolChange(state, security);
    },
    setDirection: (state, action: PayloadAction<SideEnum>) => {
      state.form.sideField = state.form.sideField.updateValue(action.payload);
      if (!state.form.customerSpreadField.isTouched) {
        const value =
          action.payload === SideEnum.Buy ? state.defaultBidOfferSpreads.offer : state.defaultBidOfferSpreads.bid;
        state.form.customerSpreadField = state.form.customerSpreadField.updateValue(value, true);
      }
    },
    setCustomerStrategy: (state, action: PayloadAction<StrategyLike>) => {
      state.form.customerStrategyField = state.form.customerStrategyField.updateValue(action.payload);
      state.form.parameters = initializeStrategyParams({
        security: state.form.symbolField.value,
        strategy: action.payload,
        isModify: false,
      });
      clearOrderMarkets(state);
    },
    setCustomerStrategyParams: (state, { payload }: PayloadAction<{ key: string; value?: any }>) => {
      const parameterField = state.form.parameters[payload.key];
      if (parameterField) {
        state.form.parameters[payload.key] = parameterField.updateValue(payload.value);
        if (payload.key === 'StartTime' && state.form.parameters.EndTime) {
          state.form.parameters.EndTime = (state.form.parameters.EndTime as DateField).setRelativeDate(payload.value);
        }
      }
    },
    setCustomerQuantity: (state, action: PayloadAction<string | undefined>) => {
      state.form.customerQuantityField = state.form.customerQuantityField.updateValue(action.payload);
    },
    setOrderCurrency: (state, action: PayloadAction<string>) => {
      state.form.orderCurrencyField = state.form.orderCurrencyField.updateValue(action.payload);
      state.form.customerQuantityField = state.form.customerQuantityField
        .updateValue('', true)
        .updateScale(getQuantityIncrement(state.form.symbolField.value, action.payload));
    },
    setCustomerSpread: (state, action: PayloadAction<string>) => {
      state.form.customerSpreadField = state.form.customerSpreadField.updateValue(action.payload);
    },
    setDefaultBidOfferSpread: (state, action: PayloadAction<{ bid: string; offer: string }>) => {
      state.defaultBidOfferSpreads = action.payload;
      if (
        (!state.form.customerSpreadField.isTouched || !state.form.customerSpreadField.hasValue) &&
        !state.orderBeingModified
      ) {
        const value = state.form.sideField.value === SideEnum.Buy ? action.payload.offer : action.payload.bid;
        state.form.customerSpreadField = state.form.customerSpreadField.updateValue(value, true);
      }
    },
    setPrincipalStrategy: (state, action: PayloadAction<OrderStrategy>) => {
      state.form.principalStrategyField = state.form.principalStrategyField.updateValue(action.payload);
      state.form.principalParameters = initializeStrategyParams({
        security: state.form.symbolField.value,
        strategy: action.payload,
        isModify: false,
      });
      const ordType = getOrdType(state.dependencies.showAllInPrices, state.form.symbolField.value, action.payload);
      state.form.principalPriceField = state.form.principalPriceField
        .setIsRequired(ordType !== 'Market')
        .setIsVisible(ordType !== 'Market');

      // Keeping simple with Sales order - if user changes strategy we reset the market accounts back to preview response default
      // This avoids us having to check that the new set available market accounts (for the new strategy) still exists on the previous selection
      state.form.orderMarketsField = state.form.orderMarketsField.updateValue(
        state.previewOrder!.Markets.map(m => m.MarketAccount || '')
      );
      state.form.useDefaultAggregationField = state.form.useDefaultAggregationField.updateValue(true);
    },
    setPrincipalStrategyParams: (state, { payload }: PayloadAction<{ key: string; value?: any }>) => {
      const parameterField = state.form.principalParameters[payload.key];
      if (parameterField) {
        state.form.principalParameters[payload.key] = parameterField.updateValue(payload.value);
        if (payload.key === 'StartTime' && state.form.principalParameters.EndTime) {
          state.form.principalParameters.EndTime = (
            state.form.principalParameters.EndTime as DateField
          ).setRelativeDate(payload.value);
        }
      }
    },
    setPrincipalPrice: (state, action: PayloadAction<string | undefined>) => {
      if (state.form.principalPriceField.isDisabled) {
        return;
      }
      state.form.principalPriceField = state.form.principalPriceField.updateValue(action.payload);
    },
    setPrincipalQuantity: (state, action: PayloadAction<string | undefined>) => {
      state.form.principalQuantityField = state.form.principalQuantityField.updateValue(action.payload);
    },
    toggleCustomerInfoSection: state => {
      state.customerInfoSectionExpanded = !state.customerInfoSectionExpanded;
    },
    setPrincipalComments: (state, action: PayloadAction<string | undefined>) => {
      state.form.principalCommentsField = state.form.principalCommentsField.updateValue(action.payload);
    },
    setPrincipalGroup: (state, action: PayloadAction<string | undefined>) => {
      state.form.principalGroupField = state.form.principalGroupField.updateValue(action.payload);
    },
    resubmitOrderRequested: (state, { payload: order }: PayloadAction<CustomerOrder>) => {
      cleanFormState(state);
      populateDropdownsFromRefData(state);
      initializeFieldsFromOrder(state, order, false);

      state.form.customerField = state.form.customerField.updateValueFromID(order?.Counterparty, true);
      state.initialized = true;
      state.isLoading = true;
    },
    resubmitOrder: (state, { payload: order }: PayloadAction<CustomerOrder>) => {
      initializeFieldsFromOrder(state, order, false);
      state.form.customerField = state.form.customerField.updateValueFromID(order?.Counterparty, true);
      state.isLoading = false;
    },
    modifyOrderRequested: (state, action: PayloadAction<{ order: CustomerOrder; principalOrderID: string }>) => {
      cleanFormState(state);
      populateDropdownsFromRefData(state);
      initializeFieldsFromOrder(state, action.payload.order, true);
      state.orderBeingModified = {
        ...action.payload.order,
        principalOrderID: action.payload.principalOrderID,
      };
      state.form.customerField = state.form.customerField.updateValueFromID(action.payload.order?.Counterparty, true);
      state.initialized = true;
      state.isLoading = true;
    },
    modifyOrder: (state, action: PayloadAction<{ order: CustomerOrder; principalOrderID: string }>) => {
      initializeFieldsFromOrder(state, action.payload.order, true);
      state.form.customerField = state.form.customerField.updateValueFromID(action.payload.order?.Counterparty, true);
      state.isLoading = false;
    },
    setIsPreviewing: (state, action: PayloadAction<boolean | undefined>) => {
      state.customerInfoSectionExpanded = false;
      state.isPreviewing = action.payload != null ? action.payload : !state.isPreviewing;
    },
    setPreviewResponse: (state, action: PayloadAction<CustomerPreviewResponse>) => {
      const order = action.payload.RoutedNewOrderSingle || action.payload.RoutedOrderCancelReplaceRequest;
      const isManualCustomerStrategy = state.form.customerStrategyField.value?.Name === ManualStrategy.Name;
      const isModifying = !!state.orderBeingModified;

      if (!order) {
        return;
      }
      state.previewOrder = order;

      state.form.principalOrderCurrencyField = state.form.principalOrderCurrencyField.updateValue(order.Currency, true);
      state.form.principalQuantityField = state.form.principalQuantityField
        .updateValue(order.OrderQty, true)
        .setDisabled(!isManualCustomerStrategy);
      updatePrincipalQuantityScale(state);

      const principalStrategy = state.form.principalStrategyField.availableItems.find(x => x.Name === order.Strategy);
      state.form.principalStrategyField = state.form.principalStrategyField
        .updateValue(principalStrategy, true)
        .setDisabled(!isManualCustomerStrategy || isModifying);
      state.form.principalPriceField = state.form.principalPriceField
        .updateValue(order.Price, true)
        .setDisabled(!isManualCustomerStrategy);
      updatePrincipalLimitPriceScale(state);

      state.form.principalCommentsField = state.form.principalCommentsField
        .updateValue(order.Comments, true)
        .setDisabled(!isManualCustomerStrategy);
      state.form.principalGroupField = state.form.principalGroupField
        .updateValue(order.Group, true)
        .setDisabled(!isManualCustomerStrategy);

      state.form.principalParameters = initializeStrategyParams({
        security: state.form.symbolField.value,
        strategy: principalStrategy,
        isModify: false,
      });
      const ordType = getOrdType(state.dependencies.showAllInPrices, state.form.symbolField.value, principalStrategy);
      state.form.principalPriceField = state.form.principalPriceField
        .setIsRequired(ordType !== 'Market')
        .setIsVisible(ordType !== 'Market');

      state.form.allocations = [
        {
          subAccountField: new Field({ value: order.SubAccount, isDisabled: true }),
          valueField: new NumericField({ value: '1', unit: Unit.Percent, isDisabled: true }),
        },
      ];

      Object.keys(state.form.principalParameters).forEach(key => {
        state.form.principalParameters[key] = state.form.principalParameters[key]
          .updateValue(order.Parameters?.[key], true)
          .setDisabled(!isManualCustomerStrategy);
      });

      if (state.form.principalParameters.StartTime && state.form.principalParameters.EndTime) {
        state.form.principalParameters.EndTime = (state.form.principalParameters.EndTime as DateField).setRelativeDate({
          type: DateTimeDurationPickerValueType.DateTime,
          value: state.form.principalParameters.StartTime.value,
        });
      }

      if (!state.form.useDefaultAggregationField.value) {
        state.form.orderMarketsField = state.form.orderMarketsField.updateValue(
          order.Markets.map(m => m.MarketAccount || '')
        );
      }
      validateUseAggregationField(state);
    },
    setOrderMarkets: (state, action: PayloadAction<string[]>) => {
      state.form.orderMarketsField = state.form.orderMarketsField.updateValue(action.payload);
      state.form.useDefaultAggregationField = state.form.useDefaultAggregationField.updateValue(false);
    },
    touchAll: state => {
      Object.keys(state.form).forEach(key => {
        if (state.form[key] instanceof BaseField) {
          state.form[key] = state.form[key].setTouched(true);
        }
      });

      Object.keys(state.form.parameters).forEach(key => {
        state.form.parameters[key] = state.form.parameters[key].setTouched(true);
      });

      if (state.isPreviewing) {
        state.form.principalStrategyField = state.form.principalStrategyField.setTouched(true);
        Object.keys(state.form.principalParameters).forEach(key => {
          state.form.principalParameters[key] = state.form.principalParameters[key].setTouched(true);
        });
      }
    },
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    resetState: state => {
      const prevSymbol = state.form.symbolField.value;
      const prevOrderCurrency = state.form.orderCurrencyField.value;
      const newState = getInitialState();
      newState.form.symbolField = newState.form.symbolField.updateValue(prevSymbol).setTouched(false);
      newState.form.orderCurrencyField = state.form.orderCurrencyField.updateValue(prevOrderCurrency).setTouched(false);
      newState.referenceData = state.referenceData;
      return newState;
    },
    setUseDefaultAggregation: (state, action: PayloadAction<boolean>) => {
      state.form.useDefaultAggregationField = state.form.useDefaultAggregationField.updateValue(action.payload);
      if (action.payload) {
        state.form.orderMarketsField = state.form.orderMarketsField.updateValue([]);
      }
      validateUseAggregationField(state);
    },
  },
  // https://redux-toolkit.js.org/api/createSlice#extrareducers
  // extraReducers allows own state update in response to action generated from other slices
  // in other words, we can listen to actions from another slice (reference data) to update this slice
  // the use case for now is when the top level OMS settings is updated, we can compare the new values (payload)
  // against the current values to determine what has changed and react appropriately
  extraReducers: builder => {
    builder.addCase(setGlobalSymbol, (state, action) => {
      salesOrderSlice.reducer(state as SalesOrderState, setSymbol(action.payload));
    });
  },
});

export const {
  onMount,
  setDependencies,
  setCustomerSecuritiesAndStrategies,
  setCustomer,
  setCustomerAccount,
  setSymbol,
  setDirection,
  setCustomerQuantity,
  setOrderCurrency,
  setCustomerSpread,
  setCustomerStrategy,
  setCustomerStrategyParams,
  setDefaultBidOfferSpread,
  toggleCustomerInfoSection,
  modifyOrder,
  modifyOrderRequested,
  setIsPreviewing,
  setPreviewResponse,
  resubmitOrderRequested,
  resubmitOrder,
  resetState,
  setReferenceData,
  setPrincipalStrategy,
  setPrincipalStrategyParams,
  setPrincipalPrice,
  setPrincipalQuantity,
  setPrincipalComments,
  setPrincipalGroup,
  setOrderMarkets,
  touchAll,
  setIsLoading,
  setUseDefaultAggregation,
} = salesOrderSlice.actions;

// DERIVED state below
export const selectModifyingCardData = createSelector(
  (state: AppState) => state.cards.openOrdersData,
  (state: AppState) => state.cards.otherOrdersData,
  (state: AppState) => state.salesOrder.orderBeingModified,
  (state: AppState) => state.cards.expandedCardID,
  (ownOrdersData, otherOrdersData, orderBeingModified, expandedCardID) => {
    if (!orderBeingModified) {
      return null;
    }
    const data =
      ownOrdersData.get(orderBeingModified.principalOrderID) ||
      otherOrdersData.get(orderBeingModified.principalOrderID);
    return expandedCardID === orderBeingModified.principalOrderID && data ? { ...data, showDetails: true } : data;
  }
);

export const selectEnableCounterCurrencySelection = createSelector(
  (state: AppState) => state.salesOrder.form.symbolField.value,
  (security?: Security) => security?.ProductType === ProductTypeEnum.Spot || canCcyFutureOrPerp(security)
);

export const selectOrdType = createSelector(
  (state: AppState) => state.salesOrder.dependencies.showAllInPrices,
  (state: AppState) => state.salesOrder.form.symbolField.value,
  (state: AppState) => state.salesOrder.form.customerStrategyField.value,
  (showAllInPrices, security, strategy) => {
    if (strategy?.Name === 'Market') {
      return OrdTypeEnum.Market;
    }
    return isOrderTypeLimitAllIn(showAllInPrices, security) ? OrdTypeEnum.LimitAllIn : OrdTypeEnum.Limit;
  }
);

export const selectIsLimitAllInOrder = createSelector(
  selectOrdType,
  (state: AppState) => !!state.salesOrder.orderBeingModified,
  (state: AppState) => state.salesOrder.form.symbolField.value,
  (state: AppState) => state.salesOrder.dependencies.showAllInPrices,
  (ordType, isModifying, security, showAllInPrices) => {
    return (
      (isModifying && ordType === OrdTypeEnum.LimitAllIn) ||
      (!isModifying && isOrderTypeLimitAllIn(showAllInPrices, security))
    );
  }
);

export const selectCustomerBalance = createSelector(
  (state: AppState) => state.salesOrder.form.customerField.value,
  (state: AppState) => state.salesOrder.form.customerAccountField.value,
  (state: AppState) => state.salesOrder.form.symbolField.value,
  (state: AppState) => state.salesOrder.dependencies.customerBalances,
  (customer?: Customer, account?: MarketAccount, security?: Security, balances?: CustomerBalance[]) => {
    return (balances || []).filter(
      b =>
        b.Counterparty === customer?.Name &&
        // For 0 balances Back-end is sending down empty MarketAccount strings
        (b.MarketAccount === account?.Name || b.MarketAccount === '') &&
        (b.Currency === security?.BaseCurrency || b.Currency === security?.QuoteCurrency)
    );
  }
);

export const selectCanPreview = createSelector(
  (state: AppState) => state.salesOrder.form,
  (state: AppState) => state.salesOrder.form.parameters,
  (form, parameters) => {
    const customerFields = [
      form.customerField,
      form.customerAccountField,
      form.symbolField,
      form.customerStrategyField,
      form.customerQuantityField,
      form.customerSpreadField,
      ...Object.values(parameters),
    ];
    const customerHasError = customerFields.some(f => f.hasError);

    return !customerHasError;
  }
);

export const selectIsFormValid = createSelector(
  selectCanPreview,
  (state: AppState) => state.salesOrder.form,
  (state: AppState) => state.salesOrder.isPreviewing,
  (state: AppState) => state.salesOrder.form.principalParameters,
  (state: AppState) => state.salesOrder.form.useDefaultAggregationField,
  (state: AppState) => state.salesOrder.form.orderMarketsField,
  (canPreview, form, isPreviewing, principalParameters, useDefaultAggregationField, orderMarketsField) => {
    const isSelectedMarketsValid = useDefaultAggregationField.value ? true : orderMarketsField.hasValue;

    const customerHasError = !canPreview || !isSelectedMarketsValid;

    const principalFields = [
      form.principalStrategyField,
      form.principalPriceField,
      form.principalQuantityField,
      ...Object.values(principalParameters),
    ];
    const principalHasError = principalFields.some(f => f.hasError);

    return isPreviewing ? !principalHasError && !customerHasError : !customerHasError;
  }
);

export const selectSubmitButtonText = createSelector(
  (state: AppState) => state.salesOrder.orderBeingModified,
  (state: AppState) => state.salesOrder.form.customerStrategyField.value,
  (state: AppState) => state.salesOrder.isPreviewing,
  (state: AppState) => state.salesOrder.form.sideField.value,
  (orderBeingModified, strategy, isPreviewing, side) => {
    return orderBeingModified
      ? 'Modify Order'
      : strategy?.Name === ManualStrategy.Name && !isPreviewing
      ? 'Enter Order Details'
      : `Place ${side} Order`;
  }
);

export const selectDisplayCounterAmount = createSelector(
  (state: AppState) => state.salesOrder.form.symbolField.value,
  security => {
    return (isSpot(security) || isFuture(security) || isPerpetualSwap(security)) && !isSecurityQuantoFuture(security);
  }
);

export const selectOrderMarketsField = createSelector(
  (state: AppState) => state.salesOrder.form.useDefaultAggregationField.value,
  (state: AppState) => state.salesOrder.form.orderMarketsField,
  (state: AppState) => state.salesOrder.previewOrder,
  (useDefaultAggregation, orderMarketsField, previewOrder) => {
    // When using defaultAggregation it means that we do not have any user selected Exchanges however we still want to
    // display the Exchanges returned on the preview response on the UI as reference for the user.
    // If they modify the order markets then defaultAggregation would become false
    // and we would use whatever user have customised
    const previewExchanges = previewOrder?.Markets.map(m => m.MarketAccount || '') || [];
    return useDefaultAggregation ? orderMarketsField.updateValue(previewExchanges) : orderMarketsField;
  }
);

const updatePrincipalLimitPriceScale = (state: WritableDraft<SalesOrderState>) => {
  const security = state.form.symbolField.value;

  state.form.principalPriceField = state.form.principalPriceField
    .updateScale(getScaleFromIncrement(security?.MinPriceIncrement))
    .updateUnit(Unit.Decimal);
};

const updatePrincipalQuantityScale = (state: WritableDraft<SalesOrderState>) => {
  const security = state.form.symbolField.value;

  state.form.principalQuantityField = state.form.principalQuantityField
    .updateScale(
      getQuantityIncrement(
        security,
        state.form.principalOrderCurrencyField.value ?? state.form.orderCurrencyField.value
      )
    )
    .updateUnit(Unit.Decimal);
};
