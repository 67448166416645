import {
  Box,
  ControlPrefix,
  FormControlSizes,
  FuseAutocompleteResult,
  itemIsAutocompleteGroup,
  SearchSelect,
  type RenderResultFunc,
} from '@talos/kyoko';
import { useMarketAccountsFilter } from 'hooks';
import { useAppStateDispatch } from 'providers/AppStateProvider';
import { useMemo } from 'react';
import {
  getPortfolioViewActions,
  usePortfolioViewStateSelector,
} from '../stateManagement/portfolioViewLayoutSlice.hooks';

const renderResult: RenderResultFunc<Item> = (searchResults, disabled) => {
  return FuseAutocompleteResult(searchResults, disabled);
};

interface Item {
  key: string;
  itemId: string;
  label: string;
}

const { changeMarketAccountId } = getPortfolioViewActions();
export const PMSMarketAccountSelector = () => {
  const { selectedMarketAccountId } = usePortfolioViewStateSelector();
  const { options, getOptionLabel } = useMarketAccountsFilter();
  const dispatch = useAppStateDispatch();

  const marketAccountOptions = useMemo(() => {
    const result = options
      .map(item => {
        return {
          key: item,
          itemId: item,
          label: getOptionLabel(item),
        };
      })
      .sort((a, b) => a.label.localeCompare(b.label));

    return result;
  }, [getOptionLabel, options]);

  const selectedAccount = useMemo(() => {
    return marketAccountOptions.find(option => option.itemId === selectedMarketAccountId);
  }, [marketAccountOptions, selectedMarketAccountId]);
  return (
    <Box w="300px" data-testid="market-account-select">
      <SearchSelect<Item>
        prefix={<ControlPrefix>Market Account:</ControlPrefix>}
        placeholder="Select..."
        selection={selectedAccount}
        renderResult={renderResult}
        dropdownWidth="300px"
        size={FormControlSizes.Small}
        getLabel={item => getOptionLabel(item.itemId)}
        showDescriptionInButton
        fuseDistance={100}
        options={marketAccountOptions}
        scrollSelectionIntoViewIndex={(scrollItems, item) =>
          scrollItems.findIndex(i => (itemIsAutocompleteGroup(i) ? 0 : i.itemId === item.itemId))
        }
        onChange={selectedItem => {
          dispatch(changeMarketAccountId(selectedItem ? selectedItem.itemId : undefined));
        }}
      />
    </Box>
  );
};
