import {
  DEFAULT_MAX_ROWS,
  ORDER,
  useConstant,
  useDynamicCallback,
  useSubscription,
  useWsPaginationLimiter,
  wsStitchWith,
  type LimitReachedChangeEvent,
  type Order,
  type OrdStatusEnum,
  type UnifiedLiquidityEnum,
  type WebsocketRequest,
} from '@talos/kyoko';
import { isEqual } from 'lodash';
import { OrgConfigurationKey, useOrgConfiguration } from 'providers';
import { useHedgeOrderStatuses } from 'providers/HedgeOrderStatusProvider';
import { useEffect, useMemo, useState } from 'react';
import { v4 as uuid } from 'uuid';

// TODO merge with def in BlotterTableOrderFilter
interface FilteredOrdersBlotterServerFilter {
  Statuses?: OrdStatusEnum[];
  Users?: string[];
  SubAccounts?: string[];
  Markets?: string[];
  SelectedMarkets?: string[];
  TradedMarkets?: string[];
  Sides?: string[];
  Strategies?: string[];
  Symbols?: string[];
  Warnings?: string[];
  ProductTypes?: string[];
  UnifiedLiquidity?: UnifiedLiquidityEnum[];
  HideApiCalls?: boolean;
  OrderID?: string;
  RFQID?: string;
  ClOrdID?: string;
  ParentOrderID?: string;
  ParentRFQID?: string;
  StartDate?: string;
  EndDate?: string;
}

interface OrderRequest extends WebsocketRequest, FilteredOrdersBlotterServerFilter {
  // TODO
}

interface UseFilteredOrdersBlotterObsParams {
  serverFilter?: FilteredOrdersBlotterServerFilter;
}

export const useFilteredOrdersBlotterObs = ({ serverFilter }: UseFilteredOrdersBlotterObsParams) => {
  const [orderRequest, setOrderRequest] = useState<OrderRequest | null>(null);

  const blotterTag = useConstant(`FILTERED_ORDERS_${uuid()}`);

  useEffect(() => {
    setOrderRequest(currentRequest => {
      const maybeNewRequest: OrderRequest = {
        name: ORDER,
        tag: blotterTag,
        sort_by: '-SubmitTime',
        IncludeHedgeOrderStatus: true,
        ...serverFilter,
      };

      return isEqual(currentRequest, maybeNewRequest) ? currentRequest : maybeNewRequest;
    });
  }, [blotterTag, serverFilter]);

  const { data: orderObs, nextPage } = useSubscription<Order>(orderRequest, { loadAll: false });

  const { getConfig } = useOrgConfiguration();
  // Get a constant limit (user has to refresh for it to change)
  // Divide the default by two because the max is being applied to two streams as opposed to one
  const limit = useConstant(getConfig(OrgConfigurationKey.BlotterRowsMax, DEFAULT_MAX_ROWS));

  const [limitReachedState, setLimitReachedState] = useState<LimitReachedChangeEvent<Order> | undefined>(undefined);

  const orderPaginationLimiter = useWsPaginationLimiter<Order>({
    startingLimit: limit,
    nextPage: nextPage,
    onLimitReachedChange: setLimitReachedState,
  });

  const pagedOrders = useMemo(
    () => orderObs.pipe(orderPaginationLimiter.wsPaginationLimiterInstance),
    [orderObs, orderPaginationLimiter.wsPaginationLimiterInstance]
  );

  const raisePaginationLimits = useDynamicCallback(() => {
    orderPaginationLimiter.raiseLimit(limit);
  });

  const { hedgeOrderStatusObs } = useHedgeOrderStatuses();

  const stitchedDataObs = useMemo(
    () =>
      pagedOrders.pipe(
        wsStitchWith({
          secondarySource: hedgeOrderStatusObs,
          getPrimaryTypeKey: order => order.OrderID,
          getSecondaryTypeKey: hos => hos.InitiatingOrderID,
          stitch: (order, hos) => {
            if (hos) {
              order.enrichWithHedgeOrderStatus(hos);
            }
            return order;
          },
        })
      ),
    [pagedOrders, hedgeOrderStatusObs]
  );

  return { dataObservable: stitchedDataObs, raisePaginationLimits, limitReachedState };
};
