import { BlotterTable, Card, type Order, useBlotterTable } from '@talos/kyoko';
import { compact } from 'lodash';
import { useMemo } from 'react';
import { of } from 'rxjs';
import { useOrder } from '../../../../../../../hooks';
import { useOrderColumns } from '../../../../../../Blotters/Orders/Columns';

interface DDHHedgeOrderDetailsProps {
  hedgeOrder: Order;
}

export const DDHHedgeOrderDetails = ({ hedgeOrder }: DDHHedgeOrderDetailsProps) => {
  const parentOrder = useOrder({ orderID: hedgeOrder.ddhParentOrderID, tag: 'OrderDetails/DDHHedgeOrderDetails' });
  const dataObservable = useMemo(() => of({ initial: true, data: compact([parentOrder]) }), [parentOrder]);
  const columns = useOrderColumns({ defaultColumns });

  const blotterTable = useBlotterTable({
    dataObservable,
    rowID: 'OrderID' satisfies keyof Order,
    columns,
  });

  return (
    /* Bit of a magic number height here. Just high enough to render header + 1 row without + 8px for a scrollbar. */
    <Card h="98px">
      <BlotterTable {...blotterTable} />
    </Card>
  );
};

const defaultColumns: Parameters<typeof useOrderColumns>[0]['defaultColumns'] = [
  { field: 'Symbol', flex: 1 },
  'Side',
  'CumQty',
  'filledPx',
  'CumAmt',
  'filledPercent',
];
