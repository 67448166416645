import type {
  GetContextMenuItemsParams,
  GridApi,
  GridOptions,
  GridOptionsWrapper,
  ICellRendererParams,
  MenuItemDef,
  ProcessCellForExportParams,
  RowNode,
} from 'ag-grid-enterprise';
import { first, isArray, isObject, last } from 'lodash';
import { logger } from '../../utils';
import { getCellDisplayValue } from '../AgGrid/agGridGetCellValue';
import type { BlotterTableContextProps } from './BlotterTableContext';
import { AGGRID_AUTOCOLUMN_ID } from './types';

/**
 * Given a list of nodes, which may be either group or leaf nodes, return all
 * the leaf nodes.
 *
 * @param node The nodes to operate on
 * @returns All leaf nodes under the given nodes
 */
export function getAllLeafNodesAfterFilter(node: RowNode | RowNode[]): RowNode[] {
  if (isArray(node)) {
    return node.flatMap(childNode => getAllLeafNodesAfterFilter(childNode));
  }
  if (!node.group) {
    return [node];
  }
  if (!node.childrenAfterFilter) {
    return [];
  }

  return node.childrenAfterFilter.flatMap(childNode => getAllLeafNodesAfterFilter(childNode));
}

/**
 * Selects or unselects all nodes within the groupNode including the groupNode itself.
 * The only selection event which will be emitted by AgGrid is the selection of this passed in groupNode, all other are suppressed.
 * If the passed in groupNode is not actually a group node (node.group === true), nothing will happen.
 * @param groupNode The groupNode within which you want to (un)select all children, including the groupNode itself.
 * @param select true to select, false to unselect
 */
export function selectOrUnselectAllNodesInGroup(groupNode: RowNode, select: boolean) {
  if (!groupNode.hasChildren()) {
    return;
  }

  if (groupNode.childrenAfterFilter != null && groupNode.childrenAfterFilter.length > 0) {
    // Here we call the recursive select function for _each child_ of groupNode, and not groupNode itself. Reason is that we _do not_ want to
    // suppress the selection changed event for the top-level groupnode, but want to do so for all recursively selected child nodes.
    // So we need to break it out into these two functions in order to achieve this.
    groupNode.childrenAfterFilter.forEach(node =>
      performActionForChildNodesRecursively(node, node => node.setSelected(select, false, true))
    );
  }

  groupNode.setSelected(select, false, false);
}

/**
 * Perform some action on behalf of the passed node and all its possible child nodes as well recursively
 */
function performActionForChildNodesRecursively(node: RowNode, action: (node: RowNode) => void) {
  action(node);
  node.childrenAfterFilter?.forEach(node => performActionForChildNodesRecursively(node, action));
}

/**
 * Get the CSV export data for a set of row nodes.
 *
 * @param params Params object from an ag-grid callback
 * @param nodeIds A set of row node ids that should be exported
 * @returns CSV export of the selected nodes, for the currently visible columns
 */
export function agGridGetCSV(params: GetContextMenuItemsParams | ICellRendererParams, nodeIds: Set<string>): string {
  return (
    params.api.getDataAsCsv({
      skipRowGroups: true,
      onlySelected: false,
      suppressQuotes: false,
      columnSeparator: ',',
      // Only include columns that are visible with header names to exclude action button / spacer columns
      columnKeys: params.columnApi.getAllDisplayedColumns()?.filter(col => col.getColDef().headerName !== ''),
      processCellCallback: getParamsFormatted,
      shouldRowBeSkipped(innerParams) {
        if (!innerParams.node?.id) {
          return true;
        }
        return !nodeIds.has(innerParams.node.id);
      },
    }) ?? ''
  );
}

export type ExportGridMode = 'CSV' | 'Excel';

/** Expanded AgGrid Context ({@link BlotterTableContextProps}) type that supports
 * value retrieval for custom grouping levels */
export interface GetValueForGroupNodeContext extends BlotterTableContextProps {
  /** Custom Override for Retrieving Group Node data */
  getValueForGroupedNode: (node: RowNode, mode: ExportGridMode) => string;
}

function isGetValueForGroupNodeContext(context: BlotterTableContextProps): context is GetValueForGroupNodeContext {
  return (context as GetValueForGroupNodeContext)?.getValueForGroupedNode != null;
}

/**
 * Get a formatted value for an ag-grid cell
 * @param params Params object from an ag-grid callback
 * @returns String representation of the cell value
 */
export function getParamsFormatted(
  params: GetContextMenuItemsParams | ProcessCellForExportParams,
  mode: ExportGridMode = 'CSV'
): string {
  // If we're working with a cell in the grouped column, we want to iterate over all layers and print the grouping structure
  // Printing a result like: "BTC > Future" for example
  const node = params.node;
  if (params.column?.getColId() === AGGRID_AUTOCOLUMN_ID && node) {
    // For tree-value grids, use the callback to process how to get the value for the group node
    if (isGetValueForGroupNodeContext(params.context.current)) {
      return params.context.current.getValueForGroupedNode(node, mode);
    } else {
      const rowGroupColumns = params.columnApi.getRowGroupColumns();
      return (
        rowGroupColumns
          // We need to get the correct value at each layer of grouping, and also pass the column for the layer of grouping
          .map(
            column =>
              getCellDisplayValue({ ...params, value: params.api.getValue(column, node), column: column }) ?? 'None'
          )
          .join(' > ')
      );
    }
  }

  const formattedValue = getCellDisplayValue(params);

  // If we got a string, return that
  if (typeof formattedValue === 'string') {
    return formattedValue;
  }

  // For size/price the value is an object containing value and currency.
  // {value: '100', currency: 'USD'}
  // In those cases access the numeric value.
  if (typeof formattedValue?.value === 'string') {
    return formattedValue.value;
  }

  // In some cases our valueGetters return arrays or objects which are then exported as [object object]. This code forces
  // them to be formatted to strings.
  if (isObject(params.value) || isArray(params.value)) {
    try {
      return JSON.stringify(params.value);
    } catch (e) {
      logger.error(e as Error);
    }
  }

  if (params.value == null) {
    return '';
  }

  return params.value.toString();
}

/**
 * Ensure that the csv cell value returned by aggrid is prefixed if the content is a formula
 */
export function cellCsvSafety(cellContent: string): string {
  // https://owasp.org/www-community/attacks/CSV_Injection
  let needsSafetyPrefix = ['=', '@', '+'].some(char => cellContent.startsWith(char));
  if (cellContent.startsWith('-')) {
    // if -, test if the cell content is a number
    if (cellContent.length > 1 && !Number.isNaN(Number(cellContent.slice(1)))) {
      return cellContent;
    }
    needsSafetyPrefix = true;
  }
  return needsSafetyPrefix ? `'${cellContent}` : cellContent;
}

export const alphabeticalGroupOrder =
  /**
   * Our default group order comparator, to ensure groups are sorted alphabetically by default
   * @param params Ag grid group order params
   */
  function alphabeticalGroupOrder({ nodeA, nodeB, columnApi, api }) {
    // The params passed to this group ordering function don't work out of the box with the getCellDisplayValue expected params
    // so we hook them up correctly here and attempt to do the ordering comparison on the display values
    const nodeADisplayValue =
      getCellDisplayValue({
        node: nodeA,
        columnApi,
        column: nodeA.rowGroupColumn,
        value: nodeA.key,
        api,
      }) || '';

    const nodeBDisplayValue =
      getCellDisplayValue({
        node: nodeB,
        columnApi,
        column: nodeB.rowGroupColumn,
        value: nodeB.key,
        api,
      }) || '';

    return nodeADisplayValue.localeCompare?.(nodeBDisplayValue);
  } satisfies GridOptions['initialGroupOrderComparator'];

/**
 * Removes repeated and last "separator" entries in a list of string | MenuItemDef
 */
export function removeUnnecessarySeparators(items: (string | MenuItemDef)[]) {
  const result = items.reduce((arr, item, index) => {
    if (item === 'separator' && arr[arr.length - 1] === item) {
      return arr;
    }

    arr.push(item);
    return arr;
  }, [] as (string | MenuItemDef)[]);

  if (last(result) === 'separator') {
    result.splice(result.length - 1, 1);
  }

  if (first(result) === 'separator') {
    result.splice(0, 1);
  }

  return result;
}

/** Defines the AgGrid "basic params properties" we need to make this function work correctly. */
type IsEditingThisCellParams = Pick<ICellRendererParams, 'api' | 'column' | 'rowIndex'>;

/**
 * Given some set of basic AgGrid function params, returns whether or not the current node is being edited.
 */
export function isEditingThisCell(params: IsEditingThisCellParams): boolean {
  const cellsBeingEdited = params.api.getEditingCells();

  // Go through all cells that are being edited right now and see if any of these cells are us.
  return cellsBeingEdited != null
    ? cellsBeingEdited.some(
        cell => cell.column.getColId() === params.column?.getColId() && cell.rowIndex === params.rowIndex
      )
    : false;
}

/**
 * Given a node, will traverse through the node.parent property upwards until it hits the root element of the blotter
 * The returned array of nodes is inclusive, meaning that the given starting node itself will also be included
 * @param node The starting node
 * @returns a list of nodes, where the first node is the starting node, and then each later index is the next parent upwards.
 * If you want the list to start with the top-level parent of this node, simply do Array.reverse() on the returned list.
 */
export function getAllParentsOfNodeInclusive(node: RowNode<any>): RowNode<any>[] {
  const parents: RowNode<any>[] = [];
  let workingNode = node;

  // In AgGrid, the omnipresent ROOT_NODE has level=-1. Iterate while level is 0 or above.
  while (workingNode.level >= 0) {
    parents.push(workingNode);
    if (workingNode.parent == null) {
      break;
    }
    workingNode = workingNode.parent;
  }

  return parents;
}

/**
 * This function returns whether or not the provided node is expanded, as in is taller / greater in height, than the
 * default row height for the grid.
 */
export function isRowHeightExpanded(node: RowNode<unknown>, api: GridApi<unknown>): boolean {
  const gridOptionsWrapper: GridOptionsWrapper | undefined = api['gridOptionsWrapper'];
  const defaultRowHeight = gridOptionsWrapper?.getRowHeightAsNumber();
  return node.rowHeight !== defaultRowHeight;
}

/**
 * This function returns the context from a given node, which is sometimes the only way to get the context in certain callbacks.
 */
export function getBlotterTableContextFromNode(node: RowNode<any>): BlotterTableContextProps | undefined {
  // @ts-expect-error There is no other way to get access to context besides accessing the private property `beans` of a node.
  return node.beans?.gridOptionsWrapper?.gridOptions?.context?.current;
}
