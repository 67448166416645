import {
  type BoxProps,
  Card,
  Flex,
  HStack,
  InlineFormattedNumber,
  isOrderComplete,
  type Order,
  Text,
} from '@talos/kyoko';
import { useDDHCalculateHedgeQty } from '../../../../../../../components/DDH/useDDHCalculateHedgeQty';
import { DDHParentOrderDetailsBlotter } from './DDHParentOrderDetailsBlotter';

interface DDHDetailsProps extends BoxProps {
  order: Order;
}

/**
 * This component shows DDH details for a DDH Parent Order. The passed in order should be a DDH Parent Order.
 */
export const DDHParentOrderDetails = ({ order, ...boxProps }: DDHDetailsProps) => {
  if (!order.HedgeOrderStatus) {
    return null;
  }

  const isComplete = isOrderComplete(order.OrdStatus);

  return (
    <Flex flexDirection="column" w="100%" gap="spacingDefault" {...boxProps}>
      {!isComplete && <EstimatedHedgeQty order={order} />}
      {/* Bit of a magic number height here. Just high enough to render header + 1 row without + 8px for a scrollbar. */}
      <Card h="98px">
        <DDHParentOrderDetailsBlotter order={order} />
      </Card>
    </Flex>
  );
};

function EstimatedHedgeQty({ order }: { order: Order }) {
  const hedgeCurrency = order.HedgeOrderStatus?.Currency;

  const { estimatedHedgeQty, delta } = useDDHCalculateHedgeQty(order, true);
  if (!estimatedHedgeQty || estimatedHedgeQty.eq(0)) {
    return null;
  }

  return (
    <HStack justifyContent="space-between" gap="spacingDefault">
      <Card flexBasis="50%" h="100%">
        <HStack justifyContent="space-between" gap="spacingDefault">
          <Text>Estimated Hedge Qty</Text>
          <InlineFormattedNumber number={estimatedHedgeQty.toFixed()} currency={hedgeCurrency} />
        </HStack>
      </Card>
      <Card flexBasis="50%" h="100%">
        <HStack justifyContent="space-between" gap="spacingDefault">
          <Text>Delta per Options Contract (Live)</Text>
          <InlineFormattedNumber number={delta?.toFixed()} />
        </HStack>
      </Card>
    </HStack>
  );
}
