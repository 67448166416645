import { AccordionGroup, Flex, type FilterClause } from '@talos/kyoko';
import { BottomTabContent } from './components/BottomTabContent';
import { TopTabContent } from './components/TopTabContent';
import { TreasuryManagementBalancesProvider } from './providers/TreasuryManagementBalancesProvider';
import { TreasuryManagementFiltersProvider } from './providers/TreasuryManagementFiltersProvider';
import { TreasuryManagementInteractionsProvider } from './providers/TreasuryManagementInteractionsProvider';

export const TreasuryManagementTab = ({
  tabId,
  initialFilterClauses,
  filtersInitialOpen,
}: {
  tabId: string;
  initialFilterClauses: FilterClause[];
  filtersInitialOpen: boolean;
}) => {
  // Within each tab we remount some providers and the components to get a clean slate each time
  return (
    <Flex h="100%" flexDirection="column" overflow="hidden">
      <TreasuryManagementBalancesProvider key={tabId}>
        <TreasuryManagementFiltersProvider>
          <TreasuryManagementInteractionsProvider>
            <AccordionGroup>
              <TopTabContent initialFilterClauses={initialFilterClauses} initialFiltersOpen={filtersInitialOpen} />
            </AccordionGroup>
            <BottomTabContent />
          </TreasuryManagementInteractionsProvider>
        </TreasuryManagementFiltersProvider>
      </TreasuryManagementBalancesProvider>
    </Flex>
  );
};
