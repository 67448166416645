import {
  BlotterTable,
  Box,
  Button,
  ButtonVariants,
  Divider,
  EXPANDABLE_HEADER_HEIGHT,
  FormControlSizes,
  HStack,
  Icon,
  IconButton,
  IconName,
  Input,
  Toggle,
  VStack,
  columnTypes,
  createCSVFileName,
  filterByCellValueMenuItem,
  filterByColumnMainMenuItems,
  getCellDisplayValue,
  getColumnKeyForFiltering,
  selectAll,
  useBlotterTable,
  useDynamicCallback,
  useGetDefaultContextMenuItems,
  useMixpanel,
  usePersistedBlotterTable,
  usePersistedRowGroupsOpenedState,
  type Column,
  type Position,
  type RowGroupsOpenedState,
} from '@talos/kyoko';
import type {
  GetContextMenuItems,
  GetContextMenuItemsParams,
  GetMainMenuItemsParams,
  GridOptions,
} from 'ag-grid-community';
import { CONTROL_TOOLTIPS } from 'containers/Blotters/tooltips';
import { compact } from 'lodash';
import { useMemo } from 'react';
import { usePositionsSubAccountsColumns } from '../../../Blotters/PositionsV3/SubAccounts/usePositionsSubAccountsColumns';
import { columnsToRequestPnlLookbacks } from '../../../Blotters/PositionsV3/SubAccounts/useSubAccountPositionsBlotterRequest';
import type { PositionColumn } from '../../../Blotters/PositionsV3/types';
import { usePositionsMenu } from '../../../Blotters/PositionsV3/usePositionsMenu';
import { AsOfDateBadge } from '../../components/AsOfDateBadge';
import { PerformanceActionType } from '../PerformanceReducer';
import { usePerformanceFilters } from '../providers/PerformanceFiltersProvider';
import { usePerformanceInteractions } from '../providers/PerformanceInteractionsProvider';
import { usePerformancePositions } from '../providers/PerformancePositionsProvider';
import { usePerformanceContext } from '../providers/PerformanceStateAndTabsProvider';
import type { PerformanceFilter } from '../types';

const ENTITY_SEARCH_KEYS: (keyof Position)[] = ['Asset', 'SubAccount'];
const ABOVE_BLOTTER_MENU_ROW_HEIGHT = '40px';
interface PerformanceBlotterProps {
  blotterID: string;
  defaultBlotterColumns: PositionColumn[];
  defaultRowGroupsOpened: RowGroupsOpenedState;
}

export const PerformanceBlotter = ({
  blotterID,
  defaultBlotterColumns,
  defaultRowGroupsOpened,
}: PerformanceBlotterProps) => {
  const mixpanel = useMixpanel();

  const defaultColumns = usePositionsSubAccountsColumns(defaultBlotterColumns);

  const persistedBlotterTable = usePersistedBlotterTable<Position>(blotterID, {
    columns: defaultColumns,
    persistFilter: false, // handled elsewhere in this case
  });

  const persistedRowGroupsOpened = usePersistedRowGroupsOpenedState(blotterID, {
    defaultRowGroupsOpened,
  });

  const { filterableProperties } = usePerformanceFilters();
  const { openClause } = usePerformanceInteractions();
  const {
    columns: positionMenuColumns,
    onClickJson,
    dialogs,
  } = usePositionsMenu({
    openClause,
    filterableProperties,
    enableBulkClose: false,
    tabType: 'Sub Accounts',
  });

  const columnsWithMenu = useMemo(
    () => [...persistedBlotterTable.columns, ...positionMenuColumns],
    [positionMenuColumns, persistedBlotterTable.columns]
  );

  const getExtraMainMenuItems = useDynamicCallback((params: GetMainMenuItemsParams) => {
    if (openClause) {
      return filterByColumnMainMenuItems({
        params,
        colIDToFilterBuilderKey,
        openClause,
        mixpanel,
      });
    }

    return [];
  });
  const getDefaultContextMenuItems = useGetDefaultContextMenuItems();

  /**
   * We don't use everything from the positionMenu, we need to do some different stuff with the context menu for example
   * so define our own callback here
   */
  const getContextMenuItems: GetContextMenuItems<Position> = useDynamicCallback((params: GetContextMenuItemsParams) => {
    const data = params.node?.data;

    const items = compact([
      selectAll(params, mixpanel),
      'separator',
      data
        ? {
            name: 'Show JSON',
            action: () => onClickJson(data),
          }
        : undefined,
      'separator',
      ...getDefaultContextMenuItems(params),
    ]);

    if (openClause) {
      items.unshift(
        ...filterByCellValueMenuItem({
          params,
          filterableProperties,
          openClause,
          colIDToFilterBuilderKey,
          mixpanel,
        })
      );
    }

    // Custom logic for the SubAccount case
    const columnKey = getColumnKeyForFiltering(params);
    if (columnKey === 'SubAccount' && params.value) {
      items.unshift({
        name: `Filter by ${getCellDisplayValue(params)}`,
        action: () =>
          dispatch({
            type: PerformanceActionType.SubAccountsChange,
            payload: {
              subAccounts: [params.value],
            },
          }),
        icon: `<i class="ag-icon ${IconName.Filter}"/>`,
      });
    }

    return compact(items);
  });

  const autoGroupColumnDef = useMemo(
    () =>
      columnTypes.group({
        type: 'group',
        title: 'Grouping',
        editable: false,
        width: 190,
        hide: false,
        suppressColumnsToolPanel: false,
        params: {
          suppressCount: true,
        },
      }),
    []
  );

  const { state, dispatch } = usePerformanceContext();

  const handleColumnsChanged = useDynamicCallback((columns: Column[]) => {
    persistedBlotterTable.onColumnsChanged(columns);
    dispatch({
      type: PerformanceActionType.PnlLookbacksChange,
      payload: {
        pnlLookbacks: columnsToRequestPnlLookbacks(columns),
      },
    });
  });

  const { positionsObs, positionTotalsObs } = usePerformancePositions();

  const blotter = useBlotterTable({
    dataObservable: positionsObs,
    pinnedRowDataObs: positionTotalsObs,
    sort: persistedBlotterTable.initialSort,
    rowID: 'rowID' satisfies keyof Position,
    columns: columnsWithMenu,
    getExtraMainMenuItems,
    getContextMenuItems,
    onColumnsChanged: handleColumnsChanged,
    onSortChanged: persistedBlotterTable.onSortChanged,
    groupRowsSticky: true,
    groupDisplayType: 'singleColumn',
    rowSelection: 'multiple',
    quickSearchParams: {
      entitySearchKeys: ENTITY_SEARCH_KEYS,
    },
    ...persistedRowGroupsOpened.blotterTableProps,
    ...({ groupRemoveSingleChildren: true, showOpenedGroup: true, autoGroupColumnDef } satisfies GridOptions),
  });

  const { expandAllGroups, collapseAllGroups } = blotter;

  const handleExport = useDynamicCallback(() => {
    // todo add as of date when thats implemented
    //const date = state.snapshotDate ? new Date(state.snapshotDate) : new Date();
    blotter.exportDataAsCSV({
      fileName: createCSVFileName({
        name: 'Performance',
      }),
    });
  });

  const handleShowZeroBalancesToggle = useDynamicCallback((checked: boolean) => {
    dispatch({
      type: PerformanceActionType.ShowZeroBalancesChange,
      payload: {
        showZeroBalances: checked,
      },
    });
  });

  return (
    <Box h={`calc(100% - ${EXPANDABLE_HEADER_HEIGHT}px)`}>
      <HStack justifyContent="space-between" gap="spacingComfortable" px="spacingDefault">
        <AsOfDateBadge snapshotDate={state.snapshotDate} />
        <HStack justifyContent="flex-end" w="100%" h={ABOVE_BLOTTER_MENU_ROW_HEIGHT} gap="spacingSmall">
          <Input
            prefix={<Icon icon={IconName.Search} />}
            clearable={true}
            size={FormControlSizes.Small}
            width="120px"
            value={blotter.blotterTableFiltersProps.quickFilterText}
            onChange={e => blotter.blotterTableFiltersProps.onQuickFilterTextChanged(e.target.value)}
          />
          <Divider orientation="vertical" mx="spacingSmall" my="spacingDefault" />
          <IconButton
            icon={IconName.ListExpand}
            size={FormControlSizes.Small}
            variant={ButtonVariants.Default}
            onClick={expandAllGroups}
          />
          <IconButton
            icon={IconName.ListCollapse}
            size={FormControlSizes.Small}
            variant={ButtonVariants.Default}
            onClick={collapseAllGroups}
          />
          <Divider orientation="vertical" mx="spacingSmall" my="spacingDefault" />
          <Toggle
            tooltip={CONTROL_TOOLTIPS.showZeroBalances}
            size={FormControlSizes.Small}
            checked={state.showZeroBalances}
            onChange={handleShowZeroBalancesToggle}
            label="Show Zero Balances"
          />
          <Divider orientation="vertical" mx="spacingSmall" my="spacingDefault" />
          <Button startIcon={IconName.DocumentUpload} size={FormControlSizes.Small} onClick={handleExport}>
            Export
          </Button>
        </HStack>
      </HStack>
      <Divider />
      <VStack h={`calc(100% - ${ABOVE_BLOTTER_MENU_ROW_HEIGHT})`} w="100%">
        <BlotterTable {...blotter} />
      </VStack>
      {dialogs}
    </Box>
  );
};

function colIDToFilterBuilderKey(id: string): keyof PerformanceFilter | undefined {
  switch (id as keyof Position) {
    case 'Asset':
      return 'Symbols';
    default:
      return undefined;
  }
}
