import { defineMessages } from 'react-intl';
import { useTheme, type DefaultTheme } from 'styled-components';

import type { ReactNode } from 'react';
import { CustomerBalanceTransactionStatusEnum, TransferStatusEnum } from '../../types/types';
import { Icon, IconName } from '../Icons';
import { IndicatorDotVariants, IndicatorDotWrapper } from '../IndicatorDot';
import { FormattedMessage } from '../Intl';
import { Wrapper } from './styles';

const messages = defineMessages({
  pendingApproval: {
    defaultMessage: 'Pending Approval',
    id: 'Status.pendingApproval',
  },
  pendingTransfer: {
    defaultMessage: 'Pending Transfer',
    id: 'Status.pendingTransfer',
  },
  waitingForExternalAuthorization: {
    defaultMessage: 'Waiting for External Authorization',
    id: 'Status.waitingForExternalAuthorization',
  },
  transferInitiated: {
    defaultMessage: 'Transfer Initiated',
    id: 'Status.transferInitiated',
  },
  transferPendingBlockchainConfirmation: {
    defaultMessage: 'Pending Blockchain Confirmation',
    id: 'Status.transferPendingBlockchainConfirmation',
  },
});

type TransferStatusProps = {
  status: TransferStatusEnum | CustomerBalanceTransactionStatusEnum;
  align?: 'left' | 'right';
  text?: string;
  theme?: DefaultTheme;
};

const StatusIcon = ({ status, ...props }) => {
  const defaultTheme = useTheme();
  const theme = props.theme ?? defaultTheme;
  const { colorTextDefault, colorTextPositive, colorTextWarning } = theme;
  switch (status) {
    case TransferStatusEnum.Completed:
      return <Icon icon={IconName.CheckCircleSolid} color={colorTextPositive} {...props} />;
    case TransferStatusEnum.Rejected:
      return <Icon icon={IconName.ExclamationCircleSolid} color={colorTextWarning} {...props} />;
    case CustomerBalanceTransactionStatusEnum.Canceled:
      return <Icon icon={IconName.CloseCircleSolid} color={colorTextDefault} {...props} />;
    case TransferStatusEnum.Pending:
    case CustomerBalanceTransactionStatusEnum.PendingApproval:
    case CustomerBalanceTransactionStatusEnum.PendingTransfer:
      return <Icon icon={IconName.Clock} color={theme.colors.blue.lighten} {...props} />;
    case TransferStatusEnum.WaitingForExternalAuth:
      return <Icon icon={IconName.KeyClock} color={theme.colors.blue.lighten} {...props} />;
    default:
      return <Icon icon={IconName.Circle} color={theme.colors.blue.lighten} {...props} />;
  }
};

export const getFriendlyTransferStatus = (
  status: TransferStatusEnum | CustomerBalanceTransactionStatusEnum
): ReactNode => {
  switch (status) {
    case CustomerBalanceTransactionStatusEnum.PendingApproval:
      return <FormattedMessage {...messages.pendingApproval} />;
    case CustomerBalanceTransactionStatusEnum.PendingTransfer:
      return <FormattedMessage {...messages.pendingTransfer} />;
    case CustomerBalanceTransactionStatusEnum.TransferInitiated:
      return <FormattedMessage {...messages.transferInitiated} />;
    case CustomerBalanceTransactionStatusEnum.TransferPendingBlockchainConfirmation:
      return <FormattedMessage {...messages.transferPendingBlockchainConfirmation} />;
    case TransferStatusEnum.WaitingForExternalAuth:
      return <FormattedMessage {...messages.waitingForExternalAuthorization} />;
    default:
      return status;
  }
};

export const getCustomerTransactionIconName = (status: CustomerBalanceTransactionStatusEnum): IconName => {
  switch (status) {
    case CustomerBalanceTransactionStatusEnum.PendingApproval:
    case CustomerBalanceTransactionStatusEnum.PendingTransfer:
      return IconName.CheckCircle;
    case CustomerBalanceTransactionStatusEnum.Rejected:
      return IconName.ExclamationCircleSolid;
    case CustomerBalanceTransactionStatusEnum.Canceled:
      return IconName.CloseCircleSolid;
    default:
      return IconName.CheckCircleSolid;
  }
};

export function TransferStatus({ status, theme, align = 'right', text, ...props }: TransferStatusProps) {
  return (
    <Wrapper iconPlacement="left" align={align} title={text} theme={theme} {...props}>
      {getFriendlyTransferStatus(status)}
      <IndicatorDotWrapper show={!!text} theme={theme} variant={IndicatorDotVariants.Warning}>
        <StatusIcon status={status} theme={theme} size="fontSizeLarge" />
      </IndicatorDotWrapper>
    </Wrapper>
  );
}
