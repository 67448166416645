import type { CareOrder, MeterWithValueProps } from '@talos/kyoko';
import {
  CareOrderStatus,
  DecisionStatusEnum,
  EMPTY_ARRAY,
  getAgGridColId,
  getOrderStatusText,
  getStatusColor,
  Icon,
  IconName,
  IndicatorBadge,
  OrderStatus,
  OrdStatusEnum,
  prettyPriceProtection,
  QuoteStatus,
  QuoteStatusEnum,
  Text,
  toBigWithDefault,
  useDefaultColumns,
  type CareOrderStatusEnum,
  type Column,
  type ColumnDef,
  type ValueOf,
} from '@talos/kyoko';
import type { ICellRendererParams, ValueFormatterParams, ValueGetterParams } from 'ag-grid-community';
import Big from 'big.js';
import { compact } from 'lodash';
import { useMemo } from 'react';
import { AgCareOrderProgress } from './AgCareOrderProgress';
import { isCareOrderRow, isOrderRow, isQuoteRow, type CareOrderBlotterEntity, type QuoteRow } from './types';
import { childRowsToMeterBars } from './utils';

export interface UseCareOrderColumns {
  defaultColumns?: (keyof CareOrder | Partial<Column>)[];
}

export const useCareOrderColumns = ({ defaultColumns = EMPTY_ARRAY }: UseCareOrderColumns) => {
  const columnDefinitions = useMemo(() => {
    return new Map<string, Column>(
      compact([
        {
          type: 'security',
          field: 'Symbol',
          title: 'Symbol',
          width: 250,
          pinned: 'left',
        },
        {
          type: 'custom',
          id: 'progress',
          pinned: 'left',
          params: {
            aggFunc: params => {
              if (params.data == null || params.values.length === 0) {
                return [];
              }
              if (isCareOrderRow(params.data)) {
                return childRowsToMeterBars(params.values, params.data.OrderQty);
              }
              throw new Error(`unexpected row type`, params.data);
            },
            valueGetter: (
              params: ValueGetterParams<CareOrderBlotterEntity>
            ): { data: CareOrderBlotterEntity; meter: MeterWithValueProps } | unknown[] => {
              if (params.node == null) {
                throw new Error('missing node in valueGetter');
              }

              // This is very confusing, but sometimes (perhaps if AG grid is fast enough?)
              // the aggFunc isn't even called, in which case the `valueGetter` will
              // indeed provide the value for the progress cell renderer.
              if (isCareOrderRow(params.data)) {
                return [];
              }

              if (isQuoteRow(params.data)) {
                const quote: QuoteRow = params.data;
                return {
                  data: quote,
                  meter: {
                    value: [QuoteStatusEnum.Filled, QuoteStatusEnum.PendingFix].includes(quote.QuoteStatus) ? 1 : 0,
                    color:
                      quote.QuoteStatus === QuoteStatusEnum.Filled
                        ? 'var(--colors-green-lighten)'
                        : 'var(--colors-blue-lighten)',
                  },
                };
              }

              if (isOrderRow(params.data)) {
                const order = params.data;
                const status = getOrderStatusText({
                  ordStatus: order.OrdStatus ?? OrdStatusEnum.PendingNew,
                  orderQty: params.data.OrderQty ?? '',
                  cumQty: params.data.CumQty ?? '',
                  decisionStatus: params.data.DecisionStatus ?? DecisionStatusEnum.Active,
                });
                return {
                  data: order,
                  meter: {
                    value: (toBigWithDefault(params.data.OrderQty, 0).gt(0)
                      ? Big(params.data.CumQty ?? '0').div(params.data.OrderQty)
                      : Big(0)
                    ).toNumber(),
                    color: getStatusColor(status, params.context.current.theme),
                  },
                };
              }

              throw new Error(`unexpected row type ${(params.data as any)?.constructor.name}`);
            },
            valueFormatter: (params: ValueFormatterParams<CareOrderBlotterEntity>) => {
              if (isCareOrderRow(params.data)) {
                return `${toBigWithDefault(params.data.CumQty, 0)
                  .div(params.data.OrderQty ?? 1)
                  .times(100)
                  .toFixed(2)}%`;
              }
              return `${Big(params.value.meter.value).times(100).toFixed(2)}%`;
            },
            cellRenderer: AgCareOrderProgress,
          },
        },
        {
          type: 'custom',
          id: 'status',
          pinned: 'left',
          params: {
            valueGetter: (params: ValueGetterParams<CareOrderBlotterEntity>) => {
              if (isCareOrderRow(params.data)) {
                return params.data.status;
              }
              if (isOrderRow(params.data)) {
                return params.data.OrdStatus;
              }
              if (isQuoteRow(params.data)) {
                return params.data.QuoteStatus;
              }
              throw new Error(`unexpected row type ${(params.data as any)?.constructor.name}`);
            },
            cellRendererSelector: (params: ICellRendererParams<CareOrderBlotterEntity>) => {
              if (isCareOrderRow(params.data)) {
                return {
                  component: ({ value }) => <CareOrderStatus status={value as ValueOf<typeof CareOrderStatusEnum>} />,
                };
              }
              if (isOrderRow(params.data)) {
                return {
                  component: ({ value }) => <OrderStatus ordStatus={value as OrdStatusEnum} />,
                };
              }
              if (isQuoteRow(params.data)) {
                return {
                  component: ({ value }) => <QuoteStatus quoteStatus={value as QuoteStatusEnum} />,
                };
              }
              throw new Error(`unexpected row type ${(params.data as any)?.constructor.name}`);
            },
          },
        },
        {
          type: 'size',
          pinned: 'left',
          field: 'OrderQty',
          title: 'Order Qty',
          params: {
            currencyField: 'Currency',
          },
        },
        {
          type: 'quoteSide',
          field: 'Side',
          title: 'Side',
          pinned: 'left',
        },
        {
          type: 'size',
          title: 'Remaining Qty',
          field: 'remainQty',
          pinned: 'left',
          params: {
            currencyField: 'Currency',
          },
        },
        {
          type: 'custom',
          title: 'Route',
          id: 'route',
          pinned: 'left',
          params: {
            valueGetter: (params: ValueGetterParams<CareOrderBlotterEntity>) => {
              if (params.data == null || params.node?.id == null) {
                return null;
              }
              if (isCareOrderRow(params.data)) {
                return params.api.getRowNode(params.node.id)?.childrenAfterGroup?.length ?? 0;
              }
              return isOrderRow(params.data) ? 'Order' : 'RFQ';
            },
            cellRenderer: (params: ICellRendererParams<CareOrderBlotterEntity>) => {
              if (params.data == null) {
                return null;
              }
              if (isCareOrderRow(params.data)) {
                return (
                  <IndicatorBadge>
                    <Icon icon={IconName.Share} />
                    <Text ml="spacingTiny">{params.value}</Text>
                  </IndicatorBadge>
                );
              }
              return params.value;
            },
          },
        },
        {
          type: 'date',
          field: 'SubmitTime',
          title: 'Submit Time',
          params: { milliseconds: true },
        },
        {
          type: 'side',
          field: 'TradedSide',
          title: 'Traded Side',
          params: {
            fallbackField: 'Side',
          },
        },
        {
          type: 'counterparty',
          field: 'Counterparty',
          title: 'Counterparty',
        },
        {
          type: 'text',
          field: 'initiatingFrom',
          title: 'Initiating From',
        },
        {
          type: 'size',
          field: 'CumQty',
          title: 'Filled Qty',
          params: {
            currencyField: 'Currency',
          },
        },
        {
          type: 'strategy',
          field: 'Strategy',
          title: 'Strategy',
          hide: true,
        },
        { field: 'selectedMarkets', title: 'Selected Markets', type: 'markets', hide: true },
        { field: 'tradedMarkets', type: 'markets', title: 'Traded Markets' },
        {
          type: 'price',
          field: 'Price',
          title: 'Price',
          hide: true,
          params: {
            assetField: 'Symbol',
          },
        },
        {
          field: 'PricingReference',
          type: 'price',
          title: 'Pricing Reference',
          params: {
            isReferencePrice: true,
          },
        },
        {
          type: 'price',
          field: 'AvgPx',
          title: 'Filled Price (no fees)',
          params: {
            assetField: 'Symbol',
          },
        },
        {
          type: 'price',
          field: 'TradedAmt',
          title: 'Traded Amt',
          params: {
            assetField: 'Symbol',
          },
          hide: true,
        },
        {
          type: 'text',
          field: 'Group',
          title: 'Group',
        },
        {
          type: 'text',
          field: 'Comments',
          title: 'Comments',
        },
        {
          field: 'CumFee',
          type: 'size',
          title: 'Fees',
          hide: true,
          params: { currencyField: 'FeeCurrency' },
        },
        { field: 'SubAccount', type: 'subAccount', title: 'Sub Account(s)' },
        { field: 'User', type: 'user' },
        { field: 'RiskStatus', type: 'bitmap', hide: true },
        {
          field: 'CumAmt',
          type: 'size',
          hide: true,
          params: { currencyField: 'AmountCurrency' },
        },
        {
          id: 'filledNotional',
          type: 'filledNotional',
          hide: true,
          title: 'Filled Notional',
          params: { securityField: 'Symbol', showFeeIcon: 'never' },
        },
        {
          id: 'openNotional',
          type: 'openNotional',
          hide: true,
          title: 'Open Notional',
          params: { securityField: 'Symbol', showFeeIcon: 'never' },
        },
        {
          id: 'totalNotional',
          type: 'totalNotional',
          hide: true,
          title: 'Total Notional',
          params: { securityField: 'Symbol', showFeeIcon: 'never' },
        },
        {
          id: 'filledCounterAmount',
          type: 'filledCounterAmount',
          hide: true,
          title: 'Filled Counter Amt',
          params: { currencyField: 'AmountCurrency', showFeeIcon: 'never' },
        },
        { id: 'productType', type: 'productType', hide: true, title: 'Product' },
        { id: 'baseQty', type: 'cumBaseQty', hide: true, title: 'Filled Base Qty' },
        { field: 'endTime', type: 'date', hide: true },
        { field: 'LastTradeTime', type: 'date', hide: true },
        { field: 'startTime', type: 'date', hide: true },
        {
          field: 'Timestamp',
          type: 'date',
          title: 'Updated at',
          params: { milliseconds: true },
        },
        { field: 'OrdType', type: 'text', hide: true, title: 'Order Type' },
        { field: 'ClOrdID', type: 'id', hide: true },
        {
          field: 'LeavesQty',
          type: 'size',
          hide: true,
          params: { currencyField: 'Currency' },
          // TODO fhqvst This one is interesting - I'm not sure 'Open Qty' is correct
          title: 'Open Qty',
        },
        { field: 'remainQty', type: 'size', hide: true, params: { currencyField: 'Currency' } },
        { field: 'TimeInForce', type: 'text', hide: true },
        { field: 'DecisionStatus', type: 'text', hide: true },
        { field: 'ExecID', type: 'text', hide: true },
        { field: 'TransactTime', type: 'date', hide: true },
        { field: 'Text', type: 'text', hide: true },
        { field: 'Parameters', type: 'orderParameters', hide: true },
        {
          field: 'ExpectedFillPrice',
          type: 'price',
          hide: true,
          params: { assetField: 'Symbol', showFeeIcon: 'never' },
        },
        { field: 'ExpectedFillQty', type: 'size', hide: true, params: { currencyField: 'Currency' } },
        { field: 'ParentOrderID', type: 'text', hide: true },
        { field: 'Revision', type: 'text', hide: true },
        { field: 'OrigClOrdID', type: 'text', hide: true },
        { field: 'SessionID', type: 'text', hide: true },
        { field: 'Allocation', type: 'text', hide: true },
        { field: 'CxlRejReason', type: 'text', hide: true },
        { field: 'AggressorSide', type: 'side', hide: true },
        {
          field: 'allowedSlippageBPS',
          title: 'Allowed Slippage',
          type: 'number',
          hide: true,
          params: { currency: 'BPS' },
        },
        {
          type: 'custom',
          params: { valueFormatter: ({ value }) => prettyPriceProtection(value) },
          field: 'priceProtection',
          hide: true,
        },
        { field: 'ParentRFQID', type: 'id', hide: true },
        { field: 'QuoteID', type: 'id', hide: true },
        { field: 'RFQID', type: 'id', hide: true },
        { field: 'unifiedLiquidity', type: 'text', hide: true },
        { field: 'reduceOnly', type: 'text', hide: true },
        { field: 'reduceFirst', type: 'text', hide: true },
        {
          field: 'FixingDetails.Index',
          title: 'Fixing Index',
          type: 'text',
        },
        {
          field: 'FixingDetails.Fixing',
          title: 'Fixing Price',
          type: 'price',
          params: {
            // TODO fhqvst this should probably be `FixingDetails.Index`
            assetField: 'Symbol',
          },
        },
        {
          field: 'Currency',
          type: 'currency',
          hide: true,
        },
        {
          field: 'QuoteReqID',
          type: 'id',
          hide: true,
        },
        {
          field: 'QuoteRequestRejectReason',
          type: 'text',
          hide: true,
        },
        {
          field: 'AmountCurrency',
          type: 'currency',
          hide: true,
        },
        {
          field: 'FirstTransactTime',
          type: 'date',
          hide: true,
        },
        {
          field: 'EndTime',
          type: 'date',
          hide: true,
        },
        {
          field: 'TradedPx',
          type: 'price',
          params: { assetField: 'Symbol' },
          hide: true,
        },
        {
          field: 'TradedQty',
          type: 'size',
          params: { currencyField: 'Currency' },
          hide: true,
        },
        {
          field: 'TradedAmt',
          type: 'size',
          params: { currencyField: 'AmountCurrency' },
          hide: true,
        },
        {
          field: 'TradedSide',
          type: 'side',
          hide: true,
        },
      ] satisfies ColumnDef<CareOrderBlotterEntity>[]).map(c => [getAgGridColId(c), c])
    );
  }, []);

  return useDefaultColumns(defaultColumns, columnDefinitions);
};
