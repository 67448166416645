import { useDynamicCallback } from '@talos/kyoko';
import { useCallback, type RefObject } from 'react';
import { useHistory, useLocation, useParams, useRouteMatch } from 'react-router-dom';
import { useMarketTabs, useMonitoringTabs } from '../../../../providers';
import { useAnalyticsTabs } from '../../../../providers/useAnalyticsTabs';
import {
  isCurrentRouteAnalyticsOrderDetails,
  isCurrentRouteCustomerOrderDetails,
  isCurrentRouteDealerMonitoringOrderDetails,
  isCurrentRouteMonitoringOrderDetails,
} from '../../../Routes/routes';
import { OrderDetails } from './OrderDetails';
import type { OrderDetailsPath } from './types';

/* Component used as a Market, Analytics and MonitoringTab */
export function OrderDetailsTab({ widthRef }: { widthRef: RefObject<HTMLDivElement> }) {
  const { orderID: orderIDProp } = useParams<{ orderID: string }>();
  const location = useLocation();
  const match = useRouteMatch();
  const isOrderIDCustomerOrder = isCurrentRouteCustomerOrderDetails(location.pathname);
  const customerOrderID = isOrderIDCustomerOrder ? orderIDProp : undefined;
  const orderID = isOrderIDCustomerOrder ? undefined : orderIDProp;

  const marketTabs = useMarketTabs();
  const monitoringTabs = useMonitoringTabs();
  const analyticsTabs = useAnalyticsTabs();

  const history = useHistory();

  const onSelectTab = useCallback(
    (index: number, path: OrderDetailsPath | undefined) => {
      if (isCurrentRouteMonitoringOrderDetails(location.pathname) && path) {
        monitoringTabs.updateOrderDetailsMonitoringTab(monitoringTabs.selectedIndex, path);
      } else if (path) {
        marketTabs.updateOrderDetailsMarketTab(marketTabs.selectedIndex, path);
      }
    },
    [location.pathname, marketTabs, monitoringTabs]
  );

  const handleClose = useDynamicCallback(() => {
    if (isCurrentRouteDealerMonitoringOrderDetails(location.pathname)) {
      // TODO add proper close support in dealer monitoring after mixed tabs is implemented
      history.push('/dealer/monitoring');
    } else if (isCurrentRouteMonitoringOrderDetails(location.pathname)) {
      monitoringTabs.removeActiveTab();
    } else if (isCurrentRouteAnalyticsOrderDetails(location.pathname)) {
      analyticsTabs.removeActiveTab();
    } else {
      marketTabs.removeActiveTab();
    }
  });

  if (match == null) {
    return null;
  }

  return (
    (orderID || customerOrderID) && (
      <OrderDetails
        key={orderID}
        widthRef={widthRef}
        orderID={orderID}
        customerOrderID={customerOrderID}
        onSelectTab={onSelectTab}
        onClose={handleClose}
      />
    )
  );
}
