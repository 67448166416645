import {
  Box,
  Checkbox,
  ConnectionType,
  Dropdown,
  EMPTY_ARRAY,
  ProductTypeEnum,
  useDropdownPopper,
  useDynamicCallback,
  useMarketAccountsContext,
  type Security,
  type StringSelectItem,
} from '@talos/kyoko';
import type { ICellEditorParams, ICellRendererParams } from 'ag-grid-enterprise';
import { forwardRef, useImperativeHandle, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { useTradableMarketAccountNames } from '../../../hooks';
import { STRATEGY_GROUP } from '../../../tokens/order';
import { MarketAccountSelectionList } from '../../MarketAccountSelectionList';

export interface AgMarketAccountSelectorProps {
  onChange: (accounts: string[]) => void;
  security?: Security;
  isDisabled?: boolean;
}

export const AgMarketAccountSelector = forwardRef(
  (
    {
      onChange,
      security,
      isDisabled = false,
      eGridCell,
      api,
      value = EMPTY_ARRAY,
      ...cellEditorParams
    }: ICellRendererParams & ICellEditorParams & AgMarketAccountSelectorProps,
    ref
  ) => {
    const availableMarketAccountNames = useTradableMarketAccountNames({
      security,
      subAccountAllocations: -1,
      connectionType: ConnectionType.Orders,
      isUnifiedLiquidityEnabled: false,
    });

    const supportAllMarketToggle = useMemo(() => security?.ProductType === ProductTypeEnum.Spot, [security]);

    const [valueState, setValueState] = useState<StringSelectItem[]>(value);
    const [useAllMarkets, setUseAllMarkets] = useState<boolean>(supportAllMarketToggle && !value.length);
    const { marketAccountsByName } = useMarketAccountsContext();

    useImperativeHandle(ref, () => {
      return {
        getValue: () => {
          return useAllMarkets ? EMPTY_ARRAY : valueState;
        },
      };
    });

    const handleMarketAccountsChange = useDynamicCallback((selectedMarketAccounts: string[] = []) => {
      const marketAccounts = selectedMarketAccounts.map(marketAccount => ({
        value: marketAccount,
        label: marketAccountsByName.get(marketAccount)?.DisplayName || marketAccount,
      }));
      setValueState(marketAccounts);
    });

    const dropdownRef = useRef<HTMLElement>(eGridCell);
    const popperPlacement = 'bottom-start';
    const handleClickOutside = useDynamicCallback(() => {
      cellEditorParams.stopEditing();
    });
    const dropdownPopper = useDropdownPopper({
      isOpen: true,
      referenceElement: dropdownRef.current,
      dropdownWidth: '240px',
      onClickOutside: handleClickOutside,
      dropdownPlacement: popperPlacement,
    });

    const handleUseAllMarkets = useDynamicCallback(() => {
      setUseAllMarkets(!useAllMarkets);
    });

    return (
      <Dropdown {...dropdownPopper} portalize={true}>
        {supportAllMarketToggle ? (
          <Box
            fontWeight={600}
            background="colors.gray.020"
            borderColor="colors.gray.040"
            borderBottom={useAllMarkets ? undefined : 'solid 1px'}
            p="spacingDefault"
          >
            <Checkbox autoFocus={useAllMarkets} checked={useAllMarkets} onChange={handleUseAllMarkets} disabled={false}>
              All Markets
            </Checkbox>
          </Box>
        ) : null}

        {(!useAllMarkets || !supportAllMarketToggle) && (
          <Wrapper>
            <MarketAccountSelectionList
              availableMarketAccounts={availableMarketAccountNames}
              selectedMarketAccounts={valueState.map(acc => acc.value)}
              security={security}
              onChangeMarketAccounts={handleMarketAccountsChange}
              connectionType={ConnectionType.Orders}
              strategyGroup={STRATEGY_GROUP.ALL}
              showBuyingPower={false}
              showInactiveMarketAccounts={false}
            />
          </Wrapper>
        )}
      </Dropdown>
    );
  }
);

const Wrapper = styled(Box)`
  scrollbar-gutter: stable both-edges;
`;
Wrapper.defaultProps = {
  maxHeight: '400px',
  overflow: 'auto',
  py: 'spacingDefault',
  background: 'colors.gray.030',
};
