import { configureStore, type Dispatch, type Store } from '@reduxjs/toolkit';
import { enableMapSet } from 'immer';
import { createContext } from 'react';
import { createDispatchHook, createSelectorHook, createStoreHook, type RootStateOrAny } from 'react-redux';
import { intlDefaultFormatter, type IntlWithFormatter } from '../../contexts/IntlContext';
import { referenceDataSlice } from './state/OMSReferenceDataSlice/reducer';
import { orderSlice } from './state/OrderSlice/reducer';
import { getInitialState } from './state/OrderSlice/utils';
import { sessionSlice } from './state/SessionSlice/reducer';
import type { RootState } from './state/types';

enableMapSet();

export const OrderFormContext = createContext<RootStateOrAny>(undefined);

export const useWLOrderFormDispatch: () => Dispatch = createDispatchHook(OrderFormContext);
export const useWLOrderFormSelector = createSelectorHook(OrderFormContext);
export const useWLOrderFormStore: () => Store<RootState> = createStoreHook(OrderFormContext);

export const getWLOrderFormStore = (
  params?: Parameters<typeof getInitialState>[0],
  intl: IntlWithFormatter = intlDefaultFormatter
) =>
  configureStore({
    preloadedState: {
      order: getInitialState(params, intl),
      referenceData: {
        securities: params ? params.securities : { securitiesBySymbol: new Map(), securitiesList: [] },
        marketAccounts: params
          ? params.marketAccounts
          : { customerMarketAccountsBySourceAccountID: new Map(), customerMarketAccountsList: [] },
        strategiesBySymbol: params?.strategiesBySymbol || new Map(),
      },
    },
    reducer: {
      order: orderSlice.reducer,
      referenceData: referenceDataSlice.reducer,
      session: sessionSlice.reducer,
    },
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware({
        serializableCheck: false,
        immutableCheck: false,
      }),
    devTools: true,
  });
