import type { ICellRendererParams } from 'ag-grid-enterprise';
import type { BigSource } from 'big.js';
import type { ReactNode } from 'react';
import { Meter, type MeterProps, type MeterWithValueProps } from '../Meter';
import { Tooltip } from '../Tooltip';

export type AgGridMeterProps = ICellRendererParams &
  MeterProps & {
    // ag-grid valueGetter can return an object with a value property, in case we need to re-render based on other properties
    value: { value: BigSource; color?: string };
    alignBar?: 'left' | 'right';
    getMeterFillPercentage?: (params: ICellRendererParams) => string;
    tooltip?: (props: { meterProps: MeterWithValueProps } & AgGridMeterProps) => ReactNode | null;
  };
export function AgGridMeter(params: AgGridMeterProps) {
  const {
    value,
    showInitialAnimation,
    alignBar = 'left',
    showLabel,
    tooltip,
    getMeterFillPercentage,
    valueFormatted,
  } = params;

  if (value == null) {
    return <span />;
  }

  const label = valueFormatted ?? undefined;

  let meterValue = value;

  if (typeof value === 'object' && 'value' in value) {
    // `value` may be an object with the actual percentage inside it
    // This allows `valueGetter` to return extra properties that may change the color of the meter
    meterValue = value.value;
  }
  if (getMeterFillPercentage) {
    meterValue = getMeterFillPercentage(params);
  }

  if (!meterValue) {
    return <span />;
  }

  const meterProps: MeterWithValueProps = {
    value: meterValue,
    label,
    showLabel,
    showInitialAnimation,
    color: value.color,
    alignBar,
  };

  const maybeTooltip = tooltip?.({ meterProps, ...params });

  if (maybeTooltip) {
    return (
      <Tooltip usePortal tooltip={maybeTooltip} targetStyle={{ width: '100%' }}>
        <Meter {...meterProps} />
      </Tooltip>
    );
  }

  return <Meter {...meterProps} />;
}
