import { createSelector, createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { BaseField, Field, NumericField, QuoteStatusEnum, SideEnum, Unit, type CustomerQuote } from '@talos/kyoko';
import Big from 'big.js';
import type { WritableDraft } from 'immer';
import type { AppState } from 'providers/AppStateProvider/types';
import { initialRefDataState } from '../OMSReferenceDataSlice';
import type { OMSReferenceDataState } from '../types';
import type { ManualRFQPricingInitPayload, ManualRFQPricingState } from './types';

export const getInitialState = (): ManualRFQPricingState => ({
  referenceData: initialRefDataState,
  form: {
    bidPriceField: new NumericField({ name: 'Sell price', unit: Unit.Bps }),
    offerPriceField: new NumericField({ name: 'Buy price', unit: Unit.Bps }),
    ttlField: new Field<string>(),
  },
  isLoading: false,
  initialized: false,
  currentCustomerQuote: undefined,
});

const disableInputFields = (state: WritableDraft<ManualRFQPricingState>, isDisabled: boolean) => {
  state.form.bidPriceField = state.form.bidPriceField.setDisabled(isDisabled);
  state.form.offerPriceField = state.form.offerPriceField.setDisabled(isDisabled);
  state.form.ttlField = state.form.ttlField.setDisabled(isDisabled);
};

export const MANUAL_RFQ_PRICING_FORM_ENABLED_STATUSES = [QuoteStatusEnum.Open, QuoteStatusEnum.PendingNew];

export const manualRFQPricingSlice = createSlice({
  name: 'manualRFQPricing',
  initialState: getInitialState(),
  reducers: {
    setReferenceData: (state, action: PayloadAction<OMSReferenceDataState>) => {
      state.referenceData = action.payload;
    },
    onMount: (state, action: PayloadAction<ManualRFQPricingInitPayload>) => {
      state.initialPayload = action.payload;
    },
    setCustomerBidSpread: (state, action: PayloadAction<string>) => {
      state.form.bidPriceField = state.form.bidPriceField.updateValue(action.payload);
    },
    setCustomerOfferSpread: (state, action: PayloadAction<string>) => {
      state.form.offerPriceField = state.form.offerPriceField.updateValue(action.payload);
    },
    setTtl: (state, action: PayloadAction<string>) => {
      state.form.ttlField = state.form.ttlField.updateValue(action.payload);
    },
    prime: (state, { payload: quote }: PayloadAction<CustomerQuote>) => {
      state.currentCustomerQuote = quote;
      state.isLoading = false;

      state.form.bidPriceField = state.form.bidPriceField
        .updateValue(quote.BidPx ? Big(quote.BidPx).times(10000).toFixed() : '')
        .setTouched(false);
      state.form.offerPriceField = state.form.offerPriceField
        .updateValue(quote.OfferPx ? Big(quote.OfferPx).times(10000).toFixed() : '')
        .setTouched(false);
      state.form.ttlField = state.form.ttlField.setTouched(false);

      if (!MANUAL_RFQ_PRICING_FORM_ENABLED_STATUSES.includes(quote.QuoteStatus)) {
        state.form.ttlField = state.form.ttlField.setDisabled(true);
        state.form.bidPriceField = state.form.bidPriceField.setDisabled(true);
        state.form.offerPriceField = state.form.offerPriceField.setDisabled(true);
        return;
      }

      if (quote.Side === SideEnum.Buy) {
        state.form.bidPriceField = state.form.bidPriceField.setDisabled(true).setIsRequired(false);
        state.form.offerPriceField = state.form.offerPriceField.setDisabled(false).setIsRequired(true);
      }
      if (quote.Side === SideEnum.Sell) {
        state.form.offerPriceField = state.form.offerPriceField.setDisabled(true).setIsRequired(false);
        state.form.bidPriceField = state.form.bidPriceField.setDisabled(false).setIsRequired(true);
      }
      if (quote.Side == null) {
        state.form.bidPriceField = state.form.bidPriceField.setDisabled(false).setIsRequired(true);
        state.form.offerPriceField = state.form.offerPriceField.setDisabled(false).setIsRequired(true);
      }
    },
    setCurrentCustomerQuote: (state, { payload: quote }: PayloadAction<CustomerQuote>) => {
      state.currentCustomerQuote = quote;
      if (MANUAL_RFQ_PRICING_FORM_ENABLED_STATUSES.includes(quote.QuoteStatus)) {
        disableInputFields(state, false);
      } else {
        disableInputFields(state, true);
      }
    },
    touchAll: state => {
      Object.keys(state.form).forEach(key => {
        if (state.form[key] instanceof BaseField) {
          state.form[key] = state.form[key].setTouched(true);
        }
      });
    },
    setQuoteRequested: state => {
      state.isLoading = true;
    },
    resetState: state => {
      const newState = getInitialState();
      newState.referenceData = state.referenceData;
      return newState;
    },
    setIsLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload;
    },
  },
});

export const {
  setReferenceData,
  onMount,
  prime,
  setCurrentCustomerQuote,
  setCustomerBidSpread,
  setCustomerOfferSpread,
  setTtl,
  setIsLoading,
  resetState,
  touchAll,
} = manualRFQPricingSlice.actions;

// DERIVED state below
export const selectCanRejectRfq = createSelector(
  (state: AppState) => state.manualRFQPricing.isLoading,
  (state: AppState) => state.manualRFQPricing.currentCustomerQuote,
  (state: AppState) => state.manualRFQPricing.form,
  (isLoading, currentCustomerQuote, form) => {
    if (currentCustomerQuote == null) {
      return false;
    }
    const quoteStatus = currentCustomerQuote.QuoteStatus;
    const isOpen = MANUAL_RFQ_PRICING_FORM_ENABLED_STATUSES.includes(quoteStatus);
    return isOpen && !isLoading;
  }
);

export const selectCanSendQuote = createSelector(
  (state: AppState) => state.manualRFQPricing.isLoading,
  (state: AppState) => state.manualRFQPricing.currentCustomerQuote,
  (state: AppState) => state.manualRFQPricing.form,
  (isLoading, currentCustomerQuote, form) => {
    if (currentCustomerQuote == null) {
      return false;
    }
    const quoteStatus = currentCustomerQuote.QuoteStatus;
    const isOpen = MANUAL_RFQ_PRICING_FORM_ENABLED_STATUSES.includes(quoteStatus);

    const isBidValid = !form.bidPriceField.hasInvalidValue;
    const isOfferValid = !form.offerPriceField.hasInvalidValue;

    let hasValidPrice = false;

    if (currentCustomerQuote.Side == null) {
      hasValidPrice = isBidValid && isOfferValid;
    } else if (currentCustomerQuote.Side === SideEnum.Buy) {
      hasValidPrice = isOfferValid;
    } else if (currentCustomerQuote.Side === SideEnum.Sell) {
      hasValidPrice = isBidValid;
    }

    return isOpen && !isLoading && hasValidPrice;
  }
);
