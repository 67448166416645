import {
  BLOTTER_TABLE_FILTERS_CONTAINER_ID,
  Card,
  ExecutionTimelineFilters,
  Flex,
  FormControlSizes,
  HStack,
  Icon,
  IconName,
  MixpanelEvent,
  NavButton,
  Portal,
  Text,
  Toggle,
  isCalendarSpread,
  isEntityRFQ,
  orderHasLegSummary,
  useMixpanel,
  useSecurity,
  type CustomerOrder,
  type Order,
} from '@talos/kyoko';
import { ActivityCard } from 'components/ActivityCard';
import * as routes from 'containers/Routes/routes';
import { useState } from 'react';
import { useTheme } from 'styled-components';
import { MultilegTable } from '../../../../../components/Multileg';
import { isMultiLegSecurity } from '../../../../../utils/security';
import { TopBarUtils } from '../TopBarUtils';
import type { OrderDetailsPath } from '../types';
import { ContractDetailsCard } from './ContractDetailsCard';
import { DDHHedgeOrderDetails } from './DDH/DDHHedgeOrderDetails/DDHHedgeOrderDetails';
import { DDHParentOrderDetails } from './DDH/DDHParentOrderDetails/DDHParentOrderDetails';
import { DetailsSectionTitle } from './DetailsSectionTitle';
import { FilledCard } from './FilledCard';
import { GeneralCard } from './GeneralCard';
import { OrderCard } from './OrderCard';
import { OtherCard } from './OtherCard';
import { ParametersCard } from './ParametersCard';
import { RFQCard } from './RFQCard';
import { CustomGridLayout, DetailsWrapper, GridItem } from './styles';

export interface DetailsTabProps {
  order: Order;
  customerOrder?: CustomerOrder;
  width: number;
}

export function DetailsTab({ order, customerOrder, width }: DetailsTabProps) {
  const mixpanel = useMixpanel();
  const { spacingSmall } = useTheme();
  const [showExpandedDetails, setShowExpandedDetails] = useState(false);
  const onChangeExpandedDetails = () => {
    mixpanel.track(MixpanelEvent.ShowExpandedDetails);
    return setShowExpandedDetails(show => !show);
  };
  const security = useSecurity(order.Symbol);
  const isOrderMultiLeg = orderHasLegSummary(order) && (isMultiLegSecurity(security) || isCalendarSpread(security));
  const [timelineFilters, setTimelineFilters] = useState<string[]>([]);
  const gridSize = width > 1600 ? 'lg' : width > 1200 ? 'md' : 'sm';

  const isDDH = order.isDDHHedgeOrder || order.isDDHParentOrder;

  return (
    <>
      <Portal portalId={BLOTTER_TABLE_FILTERS_CONTAINER_ID}>
        <HStack gap="spacingDefault">
          <Toggle
            size={FormControlSizes.Small}
            onChange={onChangeExpandedDetails}
            label="Show expanded details"
            checked={showExpandedDetails}
          />
          <TopBarUtils order={order} customerOrder={customerOrder} latestAnalytics={null} />
        </HStack>
      </Portal>
      <DetailsWrapper>
        <CustomGridLayout size={gridSize} isOrderMultiLeg={isOrderMultiLeg} isDDHEnabled={isDDH}>
          {security && isOrderMultiLeg && (
            <Flex gridArea="multileg">
              <MultilegTable order={order} orderSecurity={security} />
            </Flex>
          )}
          {order.isDDHParentOrder && (
            /* Show the section titles and render the DDHParentOrderDetails component */
            <Flex flexDirection="column" gridArea="ddh" gap="spacingDefault">
              <DetailsSectionTitle
                title="DDH Summary"
                suffix={
                  <NavButton size={FormControlSizes.Tiny} to={'ddh-orders' satisfies OrderDetailsPath}>
                    <HStack gap="spacingTiny" color="colorTextSubtle" fontWeight="bold">
                      <Text>View All DDH Orders</Text>
                      <Icon icon={IconName.ChevronRight} />
                    </HStack>
                  </NavButton>
                }
              />
              <DDHParentOrderDetails order={order} />
              <DetailsSectionTitle title="Order Summary" />
            </Flex>
          )}
          {order.isDDHHedgeOrder && order.ddhParentOrderID && (
            /* Show the section titles and render the DDHHedgeOrderDetails component */
            <Flex flexDirection="column" gridArea="ddh" gap="spacingDefault">
              <DetailsSectionTitle
                title="Parent Order Summary"
                suffix={
                  <NavButton
                    size={FormControlSizes.Tiny}
                    to={routes.getOrderDetailsRoute({ orderID: order.ddhParentOrderID, type: 'principal' })}
                  >
                    <HStack gap="spacingTiny" color="colorTextSubtle" fontWeight="bold">
                      <Text>View Parent Order Summary</Text>
                      <Icon icon={IconName.ChevronRight} />
                    </HStack>
                  </NavButton>
                }
              />
              <DDHHedgeOrderDetails hedgeOrder={order} />
              <DetailsSectionTitle title="DDH Order Summary" />
            </Flex>
          )}
          <GridItem gridArea="timeline" overflow="hidden">
            <Card
              display="flex"
              flexDirection="column"
              alignItems="stretch"
              minHeight="200px"
              pb={0}
              h="100%"
              title="Timeline"
              actions={<ExecutionTimelineFilters filters={timelineFilters} setTimelineFilters={setTimelineFilters} />}
            >
              <ActivityCard
                entity={customerOrder ?? order}
                statuses={timelineFilters}
                w={`calc(100% + ${spacingSmall}px)`}
                mx="-spacingSmall"
              />
            </Card>
          </GridItem>
          <GridItem gridArea="order">
            <OrderCard
              order={order}
              customerOrder={customerOrder}
              showExpandedDetails={showExpandedDetails}
              data-testid="ord-details-order-card"
            />
            <FilledCard order={order} customerOrder={customerOrder} showExpandedDetails={showExpandedDetails} />
            <ContractDetailsCard order={order} showExpandedDetails={showExpandedDetails} />
          </GridItem>
          <GridItem gridArea="general">
            <GeneralCard order={order} customerOrder={customerOrder} showExpandedDetails={showExpandedDetails} />
            {isEntityRFQ(order) ? (
              <RFQCard order={order} />
            ) : (
              <ParametersCard showExpandedDetails={showExpandedDetails} order={order} />
            )}
            <OtherCard order={order} showExpandedDetails={showExpandedDetails} />
          </GridItem>
        </CustomGridLayout>
      </DetailsWrapper>
    </>
  );
}
